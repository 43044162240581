function Footer() {
  return (
    <footer className="footer pd-1">
      <div className="content ">
        <p>
        © 2023 monoAI technology Co., Ltd
        </p>
      </div>
    </footer>
  );
}

export default Footer;
