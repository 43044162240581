import { useState } from "react";
import { DownloadUser } from "../../interfaces/EventDetailInterfaces";
import { Auth, API, graphqlOperation, } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetUserQuery, GetOrganizationQuery } from "../../API";
import CsvDownloader from 'react-csv-downloader';
import { message, Button} from 'antd';
import { ErrorBox } from "../ErrorBox";
import { CheckboxEmptyError } from "../../utils/customErrors";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";

type props = {
  organizationName:string
  memberIdList:string[]
};

const DownloadMemberListButton = ({organizationName,memberIdList}:props) => {

  const [isLoading, setLoading] = useState(false);
  // エラー表示用
  const [errorActive, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(<div>エラー</div>);
  // メッセージ表示するAPI用のkey
  const key = "MemberListUploader";
  
  const getData = async ():Promise<Datas> => {
    if(memberIdList.length < 1) {
      message.open({
        key,
        type: "info",
        content: "メンバーを選択してください。",
      });
      throw new CheckboxEmptyError("no checked values.");
    }
    const members: DownloadUser[] = [];

    setLoading(true);

    //loading message
    message.open({
        key,
        type: "loading",
        content: "メンバーをダウンロードしています",
        duration: 0,
    });

    const info = await Auth.currentUserInfo();
    const managerId = info.username;
      
    // Amplifyユーザー認証
    const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    //getUserでorganizationIDを取得
    const getUserResult = (await API.graphql(
      graphqlOperation(queries.getUser, { id: managerId }, authToken)
    )) as GraphQLResult<GetUserQuery>;
      
    const organizationId = getUserResult.data?.getUser?.organizationID;
    if(organizationId == null){
      throw Error("organaizationIdが取得できませんでした");
    }
    //getOrganizationでMemberを取得
    const getOrgResult = (await API.graphql(
      graphqlOperation(queries.getOrganization, { id: organizationId }, authToken)
    )) as GraphQLResult<GetOrganizationQuery>;

    for(const memberId of memberIdList) {
      const memberInfo = getOrgResult.data?.getOrganization?.Users?.items.find(e => e?.id === memberId);
      const tmp:DownloadUser = {
        email: memberInfo?.email!,
        family_name: memberInfo?.familyName!,
        given_name: memberInfo?.givenName!,
        family_name_kana: memberInfo?.familyNameKana!,
        given_name_kana: memberInfo?.givenNameKana!,
      }
      members.push(tmp);
    }
    message.destroy(key);
    setLoading(false);
    
    return members as Datas;
  };

  // エラー処理
  const handleError = (e: any): void => {
    if (e instanceof CheckboxEmptyError) return;
    // ロード中状態を解除
    setLoading(false);
    message.destroy(key);
    // エラーメッセージ
    setErrorMsg(
      <div>
        メンバーダウンロードに失敗しました
        <br />
        {e.name}:{e.message}
      </div>
    );
    setError(true);
  };
  
  const columns = [
    {
      id: 'email',
      displayName: 'email'
    },
    {
      id: 'family_name',
      displayName: '姓'
    },
    {
      id: 'given_name',
      displayName: '名'
    },
    {
      id: 'family_name_kana',
      displayName: '姓(カナ)'
    },
    {
      id: 'given_name_kana',
      displayName: '名(カナ)'
    }
  ];
  
  return (
    <>
      <Button 
      type="primary"
      className="button is-info ml-6"
      loading={isLoading}
      >
        <CsvDownloader
          filename={`${organizationName}_メンバー`}
          suffix={true}
          extension=".csv"
          separator=","
          columns={columns}
          datas={getData}
          disabled={isLoading}
          handleError={handleError}>
          ダウンロードメンバー
        </CsvDownloader>
      </Button>
    <ErrorBox msg={errorMsg} active={errorActive} title="内部エラー" setActive={setError} />
    </>
  );
    
  }
    
  export default DownloadMemberListButton;