import { Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";
import Header from "./Header";

function Admin() {

  return (
  <>
  <Header />
    <div className="columns">
      <div className="column is-2">
        <SideMenu />
      </div>
      <div className="column">
        <Outlet />
      </div>
    </div>
  </>
  );
}

export default Admin;