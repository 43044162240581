import React from "react";
import { useState, useEffect } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ErrorBox } from "./ErrorBox";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { adminAtom, UserType } from "../recoil/atoms/adminAtom";
import { isAdmin } from "../api/cognitoApi";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations"
import { Button,Modal,Input } from "antd";
import {EditOutlined,WarningOutlined} from "@ant-design/icons"
import { GetUserQuery,GetOrganizationQuery,UpdateOrganizationMutation } from "../API";

let userName:string = "";
export const setUserName = (newName:string) => {
  userName = newName;
}

/**
 * 画面上部に常時表示しているヘッダ
 */
export const CommonHeader = () => {

  const [errActive, setError] = useState(false);
  const [errmsg, setErrmsg] = useState(<div>エラー</div>);
  const navigate = useNavigate();
  // adminチェックして、値をRecoilに保存
  const [adminState, setAdminState] = useRecoilState(adminAtom);
  const thisLocation = useLocation();
  const [organizationName,setOrganizationName] = useState<string>("");
  const [role,setRole] = useState<string>("");
  const [isOrganizationNameEditOpen, setOrganizationNameEditOpen] = useState<boolean>(false);;
  const [inputValue,setInputValue] = useState<string>("");
  const [loading,setLoading] = useState<boolean>(false);
  const [organizationId,setOrganizationId] = useState<string>("");
  const [disabled,setDisabled] = useState<boolean>(false);//Modalの変更ボタン用
  const [status,setStatus] = useState<""|"error">("");//Modalのinput用
  const [prefix,setPrefix] = useState(<></>);
  const [placeholder,setPlaceholder] = useState<string>("");//Modalのinput用

  let bisAdmin = false;

  useEffect(() => {
    fetchData();
    setInputValue(organizationName);
  }, []);

  //orgNameとmemberNameを取得
  const fetchData = async () => {
    const info = await Auth.currentUserInfo();
    let familyNameFromCognito = "";
    let givenNameFromCognito = "";
    let familyNameFromDynamodb = "";
    let givenNameFromDynamodb = "";
    try {

      // check admin
      if (await isAdmin()) {
        setAdminState(UserType.Admin);
        bisAdmin = true;
      } else {
        setAdminState(UserType.User);
      }

      if(bisAdmin) {
        familyNameFromCognito = info.attributes.family_name;
        givenNameFromCognito = info.attributes.given_name;
      }

      /* MemberNameの取得 */
      if(!bisAdmin && !thisLocation.pathname.includes('/orgSignUp')){
        //userIdの取得
        const userId:string = info.username;
        // Amplifyユーザー認証
        const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
        /* MemberNameの取得 */
        const resultUser = (await API.graphql(
          graphqlOperation(queries.getUser, { id: userId }, authToken)
        )) as GraphQLResult<GetUserQuery>;
        familyNameFromDynamodb = resultUser.data?.getUser?.familyName!;
        givenNameFromDynamodb  = resultUser.data?.getUser?.givenName!;
        /* roleの取得 */
        setRole(resultUser.data?.getUser?.role!);
        /* OrganaizationNameの取得 */
        setOrganizationId(resultUser.data?.getUser?.organizationID!);
        const tempOrgId:string = resultUser.data?.getUser?.organizationID!;
        const resultOrganization = (await API.graphql(
          graphqlOperation(queries.getOrganization, { id: tempOrgId }, authToken)
        )) as GraphQLResult<GetOrganizationQuery>;
        setOrganizationName(resultOrganization.data?.getOrganization?.name!);
      }
      const  familyName = familyNameFromDynamodb || familyNameFromCognito;
      const givenName  = givenNameFromDynamodb || givenNameFromCognito;
      const fullName = familyName + " " + givenName;
      setUserName(fullName);
    } catch (e: any) {
      let errorMessage = "";
      let errorName = "";

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetchData events error:", e);
      setErrmsg(
        <div>
          ログインユーザーの組織名、姓名の取得に失敗しました。
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    }
  };

  //orgNameの更新
  const changeOrganizationName = async () => {
    try {
      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const resultOrg = (await API.graphql(
        graphqlOperation(mutations.updateOrganization, { input: { id: organizationId, name: inputValue } }, authToken)
      )) as GraphQLResult<UpdateOrganizationMutation>;
      if (resultOrg) {
        setOrganizationName(inputValue);
      }
    } catch (e: any) {
      let errorMessage = "";
      let errorName = "";

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("changeOrganizationName events error:", e);
      setErrmsg(
        <div>
          組織名の変更に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    }
  };

  const showOrganizationNameEdit = () => {
    setOrganizationNameEditOpen(true);
  };

  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if(e.target.value.length === 0){
      setDisabled(true);
      setStatus('error');
      setPrefix(<WarningOutlined rev={"title"}/>);
      setPlaceholder('組織名を入力してください');
    } else {
      setDisabled(false);
      setStatus('');
      setPrefix(<></>);
      setPlaceholder('');
    }
  }

  const handleOk = async () => {
    setLoading(true);
    //organizationNameの変更をDBに反映させる処理
    await changeOrganizationName();
    setLoading(false);
    setOrganizationNameEditOpen(false);
  };

  const handleCancel = () => {
    setOrganizationNameEditOpen(false);
  };

  const signOut = async (): Promise<void> => {
    try {
      setAdminState(UserType.Init);
      //ログアウトボタンをクリックしたとき、URLを / に変更する
      navigate("/", { replace: true });
      await Auth.signOut();

      // clear amplify localstorage
      for(let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if(key?.startsWith("Cognito")) {
          localStorage.removeItem(key);
        }
      }
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("sign out error:", e);
      setErrmsg(
        <div>
          ログアウトに失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    }
  };

  return (
    <div>
      <ErrorBox msg={errmsg} active={errActive} title="内部エラー" setActive={setError} />

      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/">
            <div className="navbar-item">
              <div className={"is-size-3"}>法人GPT</div>
            </div>
          </Link>
          {thisLocation.pathname.includes('/admin') ? <div className="navbar-item">全体管理</div> : null}
        </div>

        <div className="navbar-menu is-active">
          <div className="navbar-end">
            {adminState === UserType.Admin ? <div className="navbar-item">全体管理者</div> : null}
            {role === "manager" && thisLocation.pathname.includes('/chat') ? <div className="navbar-item"><Link to="/manager">管理者</Link></div> : null}
            <div className="navbar-item">
              {adminState === UserType.User && !thisLocation.pathname.includes('/orgSignUp') ? <p>{organizationName}</p> : null}
              {role === "manager" && adminState === UserType.User && !thisLocation.pathname.includes('/orgSignUp') ? <Button icon={<EditOutlined rev={"title"}/>} type="text" onClick={showOrganizationNameEdit}></Button> : null}
            </div>

            {<div className="navbar-item">{userName}</div>}
            <div className="navbar-item"><Button className={"button is-danger is-rounded is-small"} onClick={signOut}>ログアウト</Button></div>

            <Modal title="組織名編集" open={isOrganizationNameEditOpen} onCancel={handleCancel}
            footer={[
            <Button onClick={handleCancel} className="button is-danger">キャンセル</Button>,
            <Button onClick={handleOk} className="button is-success" loading={loading} disabled={disabled}>変更</Button>
            ]}>
              <p>組織名</p>
              <Input defaultValue={organizationName} onChange={onChange} status={status} prefix={prefix} placeholder={placeholder}></Input>
            </Modal>
          </div>
        </div>
      </nav>
    </div>
  );
}