import { NavLink } from "react-router-dom";

function SideMenu() {
  const IS_ACTIVE = "is-active";
  const admin = "/admin";
  return (
    <div>
      <aside className="menu">
        <ul className="menu-list">
          <li>
            <ul>
              <li>
                <NavLink className={({ isActive }) => (isActive ? IS_ACTIVE : "")} to={`${admin}/organizationList`} end>
                  組織一覧
                </NavLink>
              </li>
              <li>
                <NavLink className={({ isActive }) => (isActive ? IS_ACTIVE : "")} to={`${admin}/gptAdminList`} end>
                  全体管理者一覧
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </div>
  );
}

export default SideMenu;
