import { Outlet } from "react-router-dom";

const Member = () => {
  return (
    <>
      <div className="columns">
        <div className="column">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Member;