import { GraphQLResult } from "@aws-amplify/api-graphql";
import { changeToManager, createUser } from "../../api/cognitoApi"
import * as queries from "../../graphql/queries";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { useRef, useState } from "react";
import { GetUserQuery } from "../../API";
import * as mutations from "../../graphql/mutations";
import { ErrorBox } from "../ErrorBox";
import { isKatakana } from "../../utils/katakana";

/**
 * @property {Promise<void>}fetchList: 編集するユーザーデータ
 */

function AddOrganizationMemberButton(props: any) {
  const [familyNameData, setFamilyNameData] = useState<string>("");
  const [familyNameKanaData, setFamilyNameKanaData] = useState<string>("");
  const [givenNameData, setGivenNameData] = useState<string>("");
  const [givenNameKanaData, setGivenNameKanaData] = useState<string>("");
  const [emailData, setEmailData] = useState<string>("");
  const [roleData, setRoleData] = useState<string>("member");

  const modalEdit = useRef<HTMLDivElement>(null);
  const confirmOk = useRef<HTMLButtonElement>(null);

  const modalEditShow  = () => { modalEdit.current?.classList.add("is-active");};
  const modalEditClose = () => { modalEdit.current?.classList.remove("is-active");};

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg]   = useState(<div>エラー</div>);

  const resetFormData = () => {
    setFamilyNameData("");
    setFamilyNameKanaData("");
    setGivenNameData("");
    setGivenNameKanaData("");
    setEmailData("");
    setRoleData("member");
  }

  const createOrganizationMember = async (event:any) => {
    event.preventDefault();
    try{
      confirmOk.current?.classList.add("is-loading");
      const info = await Auth.currentUserInfo();

      //システム側でカナチェック
      let errorMessage = [];
      if(!isKatakana(familyNameKanaData)) errorMessage.push("姓(カナ)はカタカナで入力してください。");
      if(!isKatakana(givenNameKanaData))  errorMessage.push("名(カナ)はカタカナで入力してください。");
      if(errorMessage.length > 0)                   throw Error(errorMessage.join("\n"));

      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const resultManager = (await API.graphql(
        graphqlOperation(queries.getUser, { id: info.username }, authToken)
      )) as GraphQLResult<GetUserQuery>;
      const organizationID = resultManager.data?.getUser?.organizationID;
      const id:string = await createUser(emailData);
      if(roleData ==="manager") { // 作成時にマネージャーなら組織管理者の権限を与える
        await changeToManager(id);
      }
      if(organizationID == null) {
        throw Error("organaizationId取が得できませんでした");
      }
      const input = {
        input: {
          organizationID,
          id,
          email: emailData,
          familyName: familyNameData,
          givenName: givenNameData,
          familyNameKana: familyNameKanaData,
          givenNameKana: givenNameKanaData,
          role: roleData
        }
      };
      await API.graphql(
        graphqlOperation(mutations.createUser, input, authToken)
      );

      props.fetchList();
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("create user error:", e);
      setErrmsg(
        <div>
          メンバーの追加に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      resetFormData();
      confirmOk.current?.classList.remove("is-loading");
      modalEditClose();
    }
  }

  const handleFamilyNameData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyNameData(e.target.value)
  }
  const handleFamilyNameKanaData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyNameKanaData(e.target.value)
  }
  const handleGivenNameData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGivenNameData(e.target.value)
  }
  const handleGivenNameKanaData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGivenNameKanaData(e.target.value)
  }
  const handleEmailData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailData(e.target.value)
  }
  const handleRoleData  = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setRoleData(e.target.value)
  }

  const modalBody = () => {
    return(
      <>
        <div className="field">
          <label className="label">姓</label>
          <div className="control">
          <input className="input" value={familyNameData} onChange={handleFamilyNameData} type="text" name="familyName" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">名</label>
          <div className="control">
          <input className="input" value={givenNameData} onChange={handleGivenNameData} type="text" name="givenName" required/>
          </div>
        </div>
        <div className="field">
          <label htmlFor="" className="label">姓(カナ)</label>
          <div className="control">
          <input className="input" value={familyNameKanaData} onChange={handleFamilyNameKanaData} type="text" name="familyNameKana" pattern="[\u30A1-\u30FA\u30FC]+" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">名(カナ)</label>
          <div className="control">
          <input className="input" value={givenNameKanaData} onChange={handleGivenNameKanaData} type="text" name="givenNameKana" pattern="[\u30A1-\u30FA\u30FC]+" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">メールアドレス</label>
          <div className="control">
          <input className="input" value={emailData} onChange={handleEmailData} type="text" name="email" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">権限</label>
          <div className="control">
            <select value={roleData} onChange={handleRoleData} name="role">
              <option value="member">メンバー</option>
              <option value="manager">管理者</option>
            </select>
          </div>
        </div>
      </>
    )
  }

  const modalHtml = () => {
    return(
        <>
            <button className="button is-primary mr-1" onClick={modalEditShow}>メンバー追加</button>
            <div className="modal" ref={modalEdit}>
              <div className="modal-background"/>
              <div className="modal-card">
                <form onSubmit={createOrganizationMember} onReset={modalEditClose}>
                  <header className="modal-card-head">
                    <p className="modal-card-title">メンバー追加</p>
                    <button className="delete" aria-label="close" type="reset"></button>
                  </header>
                  <section className="modal-card-body">{modalBody()}</section>
                  <footer className="modal-card-foot">
                    <button className="button is-success" type="submit" ref={confirmOk}>
                      追加
                    </button>
                    <button className="button" type="reset">
                      キャンセル
                    </button>
                  </footer>
                </form>
              </div>
            </div>
        </>
    )
  }

  return (
    <>
      {modalHtml()}
      <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
    </>
  )
}

export default AddOrganizationMemberButton;