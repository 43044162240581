import { NavLink } from "react-router-dom";

function SideMenu() {
  const IS_ACTIVE = "is-active";
  const manager = "/manager";
  return (
    <div>
      <aside className="menu">
        <ul className="menu-list">
          <li>
            <ul>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? IS_ACTIVE : "")}
                  to={`${manager}/organizationMemberList`}
                  end
                >
                  組織メンバー一覧
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? IS_ACTIVE : "")}
                  to={`/chat`}
                  end
                >
                  AIチャット
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? IS_ACTIVE : "")}
                  to={`${manager}/memberShipCancel`}
                  end
                >
                  退会
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
    </div>
  );
}

export default SideMenu;
