import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { changeToManager } from "../../api/cognitoApi";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GetUserQuery, CreateOrganizationMutation, CreateUserInput, CreateUserMutation } from "../../API";
import { API, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";

import Header from "./Header";
import { CommonHeader } from "../CommonHeader";
import { Spin, Alert, Space, Layout, Modal, Form, Button, Input, Card} from 'antd';
import { getRole } from "../Top";
import { isKatakana } from "../../utils/katakana"
import { KatakanaError } from "../../utils/customErrors"

const OrgSignUp = (): JSX.Element => {
  // 登録用情報
  const [userEmail, setUserEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [userOrgName, setUserOrgName] = useState("");
  const [userFamilyName, setUserFamilyName] = useState("");
  const [userGivenName, setUserGivenName] = useState("");
  const [userFamilyNameKana, setUserFamilyNameKana] = useState("");
  const [userGivenNameKana, setUserGivenNameKana] = useState("");

  // Modalの中身。エラーメッセージはModalの中で表示すること
  const [isModalActive, setModal] = useState(false);
  const [isErrorActive, setError] = useState(false);
  const [errorBody, setErrorBody] = useState(<Alert message="エラー" type="error" showIcon />);

  // ロード中画面・Modal用
  const [confirmLoading, setConfirmLoading] = useState(false);

  // ナビゲーション
  const navigate = useNavigate();
  const managerRoot = "/manager";

  useEffect(() => {(
    async() => {
    await validationRole();
    fetchData();
  })()}, []);

  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result !== null) navigate("/", { replace: true });
  };

  const fetchData = async (): Promise<void> => {
    try {
      const userInfo = await Auth.currentUserInfo();
      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

      const result = (await API.graphql({
        query: queries.getUser,
        authToken: authToken,
        variables: {
            id: userInfo.username
          },
        },
      )) as GraphQLResult<GetUserQuery>;

      const orgID = result?.data?.getUser?.organizationID!;
      const role = result?.data?.getUser?.role!;
      // 一般ユーザーの場合は登録を不可にする
      if (role === "member") {
        navigate("/");
      }
      // 既に登録した場合は再登録を不可にする
      if (orgID && role === "manager")
      {
        navigate(managerRoot);
      }

      setUserEmail(userInfo.attributes.email);
      setUserID(userInfo.username);
    } catch (e: any) {
      console.log("fetch data error:", e);
    }
  };

  const validation = (): boolean => {
    return userEmail          !== "" &&
           userID             !== "" &&
           userOrgName        !== "" &&
           userFamilyName     !== "" &&
           userGivenName      !== "" &&
           userFamilyNameKana !== "" &&
           userGivenNameKana  !== "";
  };

  // フォーム内容を記録する
  const onFormFinish = (values: any): void => {
    // 入力内容を記録する
    setUserOrgName(values.organization);
    setUserFamilyName(values.familyName);
    setUserFamilyNameKana(values.familyNameKana);
    setUserGivenName(values.givenName);
    setUserGivenNameKana(values.givenNameKana);

    // 入力内容確認の画面を表示
    setError(false);
    setModal(true);
  };

  const handleModalOk = async (): Promise<void> => {
    let newOrgID = "";
    const kanaErrors: string[] = [];
    try {
      // カタカナチェック
      const familyNameKanaCheck = isKatakana(userFamilyNameKana);
      const givenNameKanaCheck = isKatakana(userGivenNameKana);
      if (!familyNameKanaCheck) {
        kanaErrors.push("セイはカタカナで入力してください。");
      }
      if (!givenNameKanaCheck) {
        kanaErrors.push("メイはカタカナで入力してください。");
      }
      if (kanaErrors.length > 0) {
        setModal(false);
        throw new KatakanaError();
      }

      if (!validation()) {
        setModal(false);
        throw new Error("ユーザー情報を取得できませんでした");
      }

      // ロード中状態に変更
      setConfirmLoading(true);

      // 組織管理者の権限を与える
      await changeToManager(userID);

      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      // 組織を新規作成
      const createOrgResult = (await API.graphql({
        query: mutations.createOrganization,
        authToken: authToken,
        variables: {
          input: {
            name: userOrgName,
          },
        },
      })) as GraphQLResult<CreateOrganizationMutation>;

      newOrgID = createOrgResult.data?.createOrganization?.id!;

      // 管理者ユーザーを新規作成
      const data: CreateUserInput = {
        id: userID,
        email: userEmail,
        organizationID: newOrgID,
        role: "manager",
        familyName: userFamilyName,
        givenName: userGivenName,
        familyNameKana: userFamilyNameKana,
        givenNameKana: userGivenNameKana,
      };
      console.log(data);

      const createUserResult = (await API.graphql({
        query: mutations.createUser,
        authToken: authToken,
        variables: {
          input:data,
        },
      })) as GraphQLResult<CreateUserMutation>;
      console.log(createUserResult.data?.createUser);

      // 登録完了・次のページに移行
      navigate(managerRoot);
    } catch (e: any) {
      console.log("create manager error:", e);
      // TODO:エラー処理の代替案
      // エラーのため、作成した組織をDBから削除する
      // if (newOrgID && newOrgID !== "") {
      //   const result = (await API.graphql({
      //     query: mutations.deleteOrganization,
      //     variables: {
      //       input: {
      //         id: newOrgID,
      //       },
      //     },
      //   })) as GraphQLResult<DeleteOrganizationMutation>;
      //   console.log("deleted the new organization with id: " + result?.data?.deleteOrganization?.id!);
      // }

      // エラーメッセージを作る
      if (e instanceof KatakanaError) {
        const info = (
          <>
            {kanaErrors[0] && <div>{kanaErrors[0]}</div>}
            {kanaErrors[1] && <div>{kanaErrors[1]}</div>}
          </>
        );
        setErrorBody(
          <Alert
            message="組織管理者情報の登録に失敗しました"
            description={info}
            type="error"
            showIcon
          />
        );
      }
      else {
        setErrorBody(
          <Alert
            message="組織管理者情報の登録に失敗しました"
            description={e.message ? e.message : "内部エラー"}
            type="error"
            showIcon
          />
        );
      }
      setError(true);
    } finally {
      setConfirmLoading(false);
      setModal(false);
    }
  };

  const handleModalCancel = (): void => {
    setModal(false);
  };

  // 入力内容確認画面
  const modalHtml = (): JSX.Element => {
    return (
        <Modal title="以下の内容で登録してよろしいですか？"
          open={isModalActive}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          confirmLoading={confirmLoading}
          okText="本登録"
          cancelText="キャンセル">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card title="法人名・組織名" size="small">
              <p>{userOrgName}</p>
            </Card>
            <Card title="管理担当者 氏名" size="small">
              <p>{userFamilyName} {userGivenName}</p>
            </Card>
            <Card title="管理担当者 氏名（カナ）" size="small">
              <p>{userFamilyNameKana} {userGivenNameKana}</p>
            </Card>
          </Space>
        </Modal>
    );
  };

  // 入力フォーム内容
  const formHtml = (): JSX.Element => {
    return (
      <Card title="組織管理者 詳細情報登録" bordered={false} style={{ width: 600, textAlign: 'left' }}>
        <Spin spinning={isModalActive}>
          <Form
            layout='vertical'
            name="form"
            onFinish={onFormFinish}
            autoComplete="off">
            <Form.Item
              name="organization"
              label="法人名・組織名"
              rules={[{ required: true }]}>
              <Input placeholder="株式会社○○○○"/>
            </Form.Item>
            <Form.Item
              name="familyName"
              label="管理担当者 姓"
              rules={[{ required: true }]}>
              <Input placeholder="山田"/>
            </Form.Item>
            <Form.Item
              name="givenName"
              label="管理担当者 名"
              rules={[{ required: true }]}>
              <Input placeholder="太郎"/>
            </Form.Item>
            <Form.Item
              name="familyNameKana"
              label="管理担当者 セイ"
              rules={[{ required: true }]}>
              <Input placeholder="ヤマダ"/>
            </Form.Item>
            <Form.Item
              name="givenNameKana"
              label="管理担当者 メイ"
              rules={[{ required: true }]}>
              <Input placeholder="タロウ"/>
            </Form.Item>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
              {isErrorActive && errorBody}
              <Button type="primary" htmlType="submit" value="large">
                  次へ
              </Button>
            </Space>
          </Form>
        </Spin>
      </Card>
    );
  };

  return (
    <>
      <CommonHeader/>
      <Header/>
      <Layout>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          {formHtml()}
          {modalHtml()}
        </div>
      </Layout>
    </>
  );
};

export default OrgSignUp;