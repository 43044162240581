/** 
 * スピナー表示
 */
 import { useState, useEffect } from "react";
 import {SpinnerProps} from "./../interfaces/DialogInterface";
 
 /** 
  * @module Spinner
  * @param {boolean} active - 表示/非表示
  * @return {any} - HTML
  *
  *    インターフェーイス
  *        DialogInterface.ts
  *            SpinnerProps
  * 
  *    CSS
  *        ez10.css
  * 
  *    使用例
  *        <Spinner active = {active}/>
  */
 export function Spinner(props: SpinnerProps)
 {
   const [active, setActive] = useState(false);
   useEffect(() => {
     setActive(props.active);
   }, [props.active]);
     
   return(
     <div>
       <div id="loading" className = {active ? "loading" : "loaded"}>
         <div  className = {active ? "spinner" : "none"}></div>
       </div>
     </div>
   );
 }