import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, Auth } from "aws-amplify";
import { useRef, useState } from "react";
import { UpdateUserMutation } from "../../API";
import * as mutations from "../../graphql/mutations";
import { ErrorBox } from "../ErrorBox";
import { setUserName } from "../CommonHeader";
import { changeRole } from "../../api/cognitoApi";
import { isKatakana } from "../../utils/katakana";

type UpdateUser = {
  id?:             string;
  familyName?:      string;
  organizationID?: string;
  familyNameKana?:  string;
  givenName?:       string;
  givenNameKana?:   string;
  email?:          string;
  role?:           string;
}

/**
 * @property {UpdateUser}userData: 編集するユーザーデータ
 * @description 編集ボタンを追加したい場所に記述する
 */
function OrganizationMemberEdit(props: any) {

  const modalEdit = useRef<HTMLDivElement>(null);
  const confirmOk = useRef<HTMLButtonElement>(null);

  const modalEditShow  = async () => {
    await fetchData();
    modalEdit.current?.classList.add("is-active");
  };
  const modalEditClose = () => { modalEdit.current?.classList.remove("is-active");};

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg]   = useState(<div>エラー</div>);
  const [currentUserId, setCurrentUserId]   = useState("");

  const fetchData = async () => {
    const userInfo = await Auth.currentUserInfo();
    setCurrentUserId(userInfo.username)

    const options = document.getElementsByTagName("option");
    for(let i = 0; i < options.length; i++){
      if(options[i].value === props.userData.role)  options[i].selected = true;
    }
  }

  const getUpdatingDataByForm = (e:any):UpdateUser => {
    const formData = new FormData(e.target);
    const formJson = Object.fromEntries(formData.entries());

    return {
      id:             props.userData.id       as string,
      familyName:     formJson.familyName     as string,
      givenName:      formJson.givenName      as string,
      familyNameKana: formJson.familyNameKana as string,
      givenNameKana:  formJson.givenNameKana  as string,
      role:           formJson.role           as string,
    };
  }

  //データチェック
  const validation = (Data:UpdateUser): boolean => {
    //更新が不要なデータを除く
    if(props.userData.familyName     == null || props.userData.familyName     === Data.familyName)     delete Data.familyName;
    if(props.userData.givenName      == null || props.userData.givenName      === Data.givenName)      delete Data.givenName;
    if(props.userData.familyNameKana == null || props.userData.familyNameKana === Data.familyNameKana) delete Data.familyNameKana;
    if(props.userData.givenNameKana  == null || props.userData.givenNameKana  === Data.givenNameKana)  delete Data.givenNameKana;
    if(props.userData.role           == null || props.userData.role           === Data.role || Data.role == null) delete Data.role;
    if(Object.keys(Data).length <= 1) return false; //更新内容に変化がない
    return true;
  }

  const updateUser = async (Data:UpdateUser) => {
    // Amplifyユーザー認証
    const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const result = (await API.graphql({
      query: mutations.updateUser,
      authToken: authToken,
      variables: {
        input: Data,
        condition: {email:{eq:props.userData.email}}
      }
    })) as GraphQLResult<UpdateUserMutation>;
    console.log(result.data?.updateUser);

    const info = await Auth.currentUserInfo();
    const userId:string = info.username;
    if(Data.id === userId){
      const familyName = Data.familyName || props.userData.familyName;
      const givenName = Data.givenName || props.userData.givenName;
      const fullName = familyName + " " + givenName;
      setUserName(fullName);
    }
  }

  const updateOrganizationMember = async (e:any) => {
    e.preventDefault();
    try {
      confirmOk.current?.classList.add("is-loading");
      const updatingData = getUpdatingDataByForm(e);
      console.log(updatingData);

      if(currentUserId === props.userData.id && props.userData.role !== updatingData.role && updatingData.role != null)
      {
        throw Error("自分自身の権限を変更することはできません");
      }

      //システム側でカナチェック
      let errorMessage = [];
      if(!isKatakana(updatingData.familyNameKana!)) errorMessage.push("姓(カナ)はカタカナで入力してください。");
      if(!isKatakana(updatingData.givenNameKana!))  errorMessage.push("名(カナ)はカタカナで入力してください。");
      if(errorMessage.length > 0)                   throw Error(errorMessage.join("\n"));

      //更新内容に変化がある場合のみ更新
      if(validation(updatingData)){
        if(updatingData.role != null) await changeRole(props.userData.id,updatingData.role);
        await updateUser(updatingData);
        console.log(updatingData);
        await fetchData();
        props.fetchList();
      }
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("edit user error:", e);
      setErrmsg(
        <div>
          ユーザー情報の編集に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      confirmOk.current?.classList.remove("is-loading");
      modalEditClose();
    }
  }

  const modalBody = () => {
    return (
      <>
        <div className="field">
          <label className="label">姓</label>
          <div className="control">
            <input className="input" type="text" name="familyName" defaultValue={props.userData.familyName} required/>
          </div>
        </div>
        <div className="field">
          <label className="label">名</label>
          <div className="control">
            <input className="input" type="text" name="givenName" defaultValue={props.userData.givenName} required/>
          </div>
        </div>
        <div className="field">
          <label className="label">姓(カナ)</label>
          <div className="control">
            <input className="input" type="text" name="familyNameKana" defaultValue={props.userData.familyNameKana} pattern="[\u30A1-\u30FA\u30FC]+" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">名(カナ)</label>
          <div className="control">
            <input className="input" type="text" name="givenNameKana" defaultValue={props.userData.givenNameKana} pattern="[\u30A1-\u30FA\u30FC]+" required/>
          </div>
        </div>
        <div className="field">
          <label className="label">権限</label>
          <div className="control">
            {currentUserId === props.userData.id ?
              (<>
                <select name="role" disabled>
                  <option value="member">メンバー</option>
                  <option value="manager">管理者</option>
                </select>
                <p>※自分自身の権限は変更できません。</p>
              </>
              )
              :
              (<select name="role" >
                <option value="member">メンバー</option>
                <option value="manager">管理者</option>
              </select>
              )
            }
          </div>
        </div>
      </>
    )
  }
  const modalHtml = () => {
    return (
      <div>
        <button className="button is-primary" onClick={modalEditShow}>編集</button>
        <div className="modal" ref={modalEdit}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <form onSubmit={updateOrganizationMember} onReset={modalEditClose}>
              <header className="modal-card-head">
                <p className="modal-card-title">メンバー編集</p>
                <button className="delete" aria-label="close" type="reset"></button>
              </header>
              <section className="modal-card-body">{modalBody()}</section>
              <footer className="modal-card-foot">
                <button className="button is-success" type="submit" ref={confirmOk}>
                  編集
                </button>
                <button className="button" type="reset">
                  キャンセル
                </button>
              </footer>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {modalHtml()}
      <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
    </div>
  );
}

export default OrganizationMemberEdit;
