import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRole, Roles } from "../Top";

function GPT() {
  const url = process.env.REACT_APP_CHATBOTUI_URL;

  useEffect(() => {(
    async() => {
    await validationRole();
  })()}, []);

  const navigate = useNavigate();
  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result === Roles.admin) navigate("/", { replace: true });
  };
  return (
    <div style={{ width: "100%", height: "80vh" }}>
      <iframe title="GPT" src={url} style={{ width: "100%", height: "100%", border: "none" }} />
    </div>
  );
}

export default GPT;