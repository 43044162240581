import { Amplify, API, Auth } from "aws-amplify";
import { useEffect } from "react";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { CreateOrganizationMutation, CreateUserInput, CreateUserMutation, ListOrganizationsQuery } from "../API";
import {
  AdminCreateUserCommand,
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from "@aws-sdk/client-cognito-identity-provider";

import awsconfig from "../aws-exports";
import {
  changeToManager,
  createAdminUser,
  createUser,
  deleteAdminUser,
  deleteUser,
  isAdmin,
  listAdminUsers,
  updateAdminUser,
} from "../api/cognitoApi";
import { listOrganizations, listUsers as listUsersDynamoDB } from "../api/dynamodbApi";
Amplify.configure(awsconfig);

function Example() {
  useEffect(() => {
    //graphqlTest();
  }, []);

  // eslint-disable-next-line
  async function graphqlTest() {
    const result = (await API.graphql({
      query: queries.listOrganizations,
    })) as GraphQLResult<ListOrganizationsQuery>;
    console.log(result.data?.listOrganizations?.items);
  }

  // eslint-disable-next-line
  async function createOrg() {
    const result = (await API.graphql({
      query: mutations.createOrganization,
      variables: {
        input: {
          name: "Org3",
        },
      },
    })) as GraphQLResult<CreateOrganizationMutation>;
    console.log(result.data?.createOrganization);
  }

  async function getInfo() {
    const info = await Auth.currentUserInfo();
    console.log(info);
  }

  // eslint-disable-next-line
  async function cognitoTest() {
    const cred = await Auth.currentCredentials();
    console.log(cred);

    const client = new CognitoIdentityProviderClient({
      region: "us-west-2",
      credentials: {
        accessKeyId: cred.accessKeyId,
        secretAccessKey: cred.secretAccessKey,
        sessionToken: cred.sessionToken,
      },
    });

    const input = {
      UserPoolId: "us-west-2_qzQGdtTcK",
    };

    const command = new ListUsersCommand(input);
    const response = await client.send(command);
    console.log(response);
    response.Users?.forEach((v) => {
      if (v.Attributes) {
        console.log(v.Attributes.find((v) => v.Name === "email")?.Value);
      }
    });
  }

  // eslint-disable-next-line
  async function addUser() {
    const cred = await Auth.currentCredentials();
    const client = new CognitoIdentityProviderClient({
      region: "us-west-2",
      credentials: {
        accessKeyId: cred.accessKeyId,
        secretAccessKey: cred.secretAccessKey,
        sessionToken: cred.sessionToken,
      },
    });

    const input = {
      // AdminCreateUserRequest
      UserPoolId: "us-west-2_qzQGdtTcK",
      Username: "test@sorcerer.co.jp", // required
      TemporaryPassword: "12345678",
      UserAttributes: [
        // AttributeListType
        {
          // AttributeType
          Name: "email", // required
          Value: "test@sorcerer.co.jp",
        },
      ],
      ForceAliasCreation: true,
      MessageAction: "SUPPRESS",
      DesiredDeliveryMediums: [
        // DeliveryMediumListType
        "EMAIL",
      ],
    };

    const command = new AdminCreateUserCommand(input);
    const response = await client.send(command);
    console.log(response);
  }

  // async function listAdminUsers() {
  //   const apiName = "AdminQueries";
  //   const path = "/listUsersInGroup";
  //   const myInit = {
  //     queryStringParameters: {
  //       groupname: "Admin",
  //     },
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
  //     },
  //   };

  //   let result = await API.get(apiName, path, myInit);
  //   console.log("admin list:", result.Users);
  //   return result.Users;
  // }

  async function listUsers() {
    const apiName = "AdminQueries";
    const path = "/listUsers";
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    let result = await API.get(apiName, path, myInit);
    console.log("user list:", result);
    return result.Users;
  }

  // eslint-disable-next-line
  async function listGroupsForUser() {
    const apiName = "AdminQueries";
    const path = "/listGroupsForUser";
    const myInit = {
      queryStringParameters: {
        username: "75413858-859b-4cff-a647-4c09599be6a2",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    let result = await API.get(apiName, path, myInit);
    console.log("list groups for user:", result);
  }

  // eslint-disable-next-line
  async function createAdmin() {
    const apiName = "AdminQueries";
    const path = "/createAdmin";
    const myInit = {
      body: {
        user: {
          email: "1wangyumeng@gmail.com",
          family_name: "open",
          given_name: "api",
        },
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    let result = await API.post(apiName, path, myInit);
    console.log("create admin:", result);
  }

  async function getTokenCount() {
    const apiName = "AdminQueries";
    const path = "/gptTokenCount";
    const myInit = {
      queryStringParameters: {
        str: "Humans are strange creatures, and ever so curious too!",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    let result = await API.get(apiName, path, myInit);
    console.log("token count:", result);
  }

  const userData: CreateUserInput[] = [];

  async function setGraphqlData() {
    const result = (await API.graphql({
      query: mutations.createOrganization,
      variables: {
        input: {
          name: "株式会社ソーサラー",
        },
      },
    })) as GraphQLResult<CreateOrganizationMutation>;

    const orgId = result.data?.createOrganization?.id!;
    console.log(orgId);
    // create user
    for (let i = 0; i < userData.length; i++) {
      const input = userData[i];
      userData[i].organizationID = orgId;
      const result1 = (await API.graphql({
        query: mutations.createUser,
        variables: {
          input,
        },
      })) as GraphQLResult<CreateUserMutation>;
      console.log(result1.data?.createUser);
    }
  }

  // eslint-disable-next-line
  async function deleteOrganization() {
    const apiName = "AdminQueries";
    const path = "/deleteOrganization";
    const myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };

    let result = await API.get(apiName, path, myInit);
    console.log("delete organization:", result);
  }

  return (
    <>
      <button onClick={listUsers}> list</button>
      <button
        onClick={async () => {
          console.log(await listAdminUsers());
        }}
      >
        list admin
      </button>
      <button
        onClick={async () => {
          console.log(await createUser("1wangyumeng@gmail.com"));
        }}
      >
        create user
      </button>
      <button
        onClick={async () => {
          console.log(await deleteUser("f64bcf74-e954-4e6b-b48e-c2625a7668bf"));
        }}
      >
        delete user
      </button>
      <button onClick={getInfo}> get info</button>
      <button
        onClick={async () => {
          console.log(await createAdminUser("1wangyumeng@gmail.com", "open", "api"));
        }}
      >
        create admin
      </button>
      <button
        onClick={async () => {
          console.log(await deleteAdminUser("b7262c1a-f817-4033-841c-eda01e6be576"));
        }}
      >
        delete admin
      </button>
      <button onClick={getTokenCount}>get token count</button>
      <button
        onClick={async () => {
          await createUser("1wangyumeng@gmail.com");
          await changeToManager("1wangyumeng@gmail.com");
        }}
      >
        register
      </button>
      <button onClick={setGraphqlData}>set graphql data</button>
      <button
        onClick={async () => {
          console.log(await listOrganizations());
        }}
      >
        list organizations
      </button>
      {/* <button onClick={deleteOrganization}>delete organization</button> */}
      <button
        onClick={async () => {
          console.log(await listUsersDynamoDB());
        }}
      >
        list users dynamodb
      </button>
      <button
        onClick={async () => {
          console.log(await isAdmin());
        }}
      >
        isAdmin
      </button>
      <button
        onClick={async () => {
          await updateAdminUser("8f57e047-3a3e-495b-ab5b-3a14771f057d", "open", "api");
        }}
      >
        updateAdminUser
      </button>
    </>
  );
}

export default Example;
