import { useEffect, useRef, useState } from "react";
import { Location, useLocation } from "react-router-dom";

function Header() {
  const [pageLabel, setPageLabel] = useState("");
  const refCallback = useRef<undefined | ((location: Location) => void)>()
  const location = useLocation()

  const checkURL = () => {
    const urlList = window.location.href.split("/");
    switch(urlList.slice(-1)[0]) {
      case "organizationList":
        setPageLabel("組織一覧");
        break;
      case "gptAdminList":
        setPageLabel("全体管理者一覧");
        break;
      case "admin":
        setPageLabel("全体管理");
        break;
      default:
        setPageLabel("");
        break;
    }
  }

  useEffect(() => {
    refCallback.current = checkURL
  }, [checkURL])

  useEffect(() => {
    if (refCallback.current) {
      refCallback.current(location)
    }
  }, [location])

  return (
    <section className="hero is-link is-small">
      <div className="hero-body">
        <p className="title is-primary">
		      {pageLabel}
        </p>
      </div>
    </section>
  );
}

export default Header;
