import { useEffect, useRef, useState } from "react";
import { createAdminUser, deleteAdminUser, listAdminUsers } from "../../api/cognitoApi";
import { setSpinner } from "../App";
import { ErrorBox } from "../ErrorBox";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Roles, getRole } from "../Top";

interface Admin {
  familyName: string;
  givenName:  string;
  email:     string;
}

interface AdminData extends Admin{
  id:        string;
}

function GPTAdminList() {
  const [users, setUsers] = useState<AdminData[]>([]);
  const [currentUser, setCurrentUser]       = useState<AdminData>({id:"",familyName:"",givenName:"",email:""});
  const [deleteUserData, setDeleteUserData] = useState<AdminData>({id:"",familyName:"",givenName:"",email:""});
  const [familyNameData, setFamilyNameData] = useState<string>("");
  const [givenNameData, setGivenNameData] = useState<string>("");
  const [emailData, setEmailData] = useState<string>("");

  const modalCreate = useRef<HTMLDivElement>(null);
  const confirmOk   = useRef<HTMLButtonElement>(null);

  const modalDelete   = useRef<HTMLDivElement>(null);
  const confirmDelete = useRef<HTMLButtonElement>(null);

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg]   = useState(<div>エラー</div>);

  useEffect(() => {(
    async() => {
    await validationRole();
    fetchData();
  })()}, []);

  const navigate = useNavigate();
  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result !== Roles.admin) navigate("/", { replace: true });
  };

  const fetchData = async ():Promise<void> => {
    try {
      setSpinner(true);

      const userinfo = await Auth.currentUserInfo();
      console.log(userinfo);
      setCurrentUser({
        id:        userinfo.username,
        familyName: userinfo.attributes.family_name,
        givenName:  userinfo.attributes.given_name,
        email:     userinfo.attributes.email
      });

      const data = await listAdminUsers();
      const list = data.map((v: any): AdminData => {
        return {
          id:        v.sub,
          familyName: v.family_name,
          givenName:  v.given_name,
          email:     v.email,
        };
      });

      setUsers([...list]);
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetch list error:", e);
      setErrmsg(
        <div>
          管理者データの取得に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      setSpinner(false);
    }
  };

  const validation = (userData:Admin):boolean => {
    if(userData.familyName == null || userData.familyName === "") return false;
    if(userData.givenName  == null || userData.givenName  === "") return false;
    if(userData.email      == null || userData.email      === "") return false;
    return true;
  };

  const handleFamilyNameData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFamilyNameData(e.target.value)
  };
  const handleGivenNameData  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGivenNameData(e.target.value)
  };
  const handleEmailDataa  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailData(e.target.value)
  };

  const resetFormData = () => {
    setFamilyNameData("");
    setGivenNameData("");
    setEmailData("");
  };

  const createAdmin = async (e:any):Promise<void> => {
    e.preventDefault();
    try {
      const userData = {
        familyName: familyNameData,
        givenName: givenNameData,
        email: emailData
      }

      //validation check
      if (!validation(userData)) {
        modalCreateClose();
        throw new Error("値を入力してください");
      }

      //loading logo
      confirmOk.current?.classList.add("is-loading");

      await createAdminUser(
        userData.email,
        userData.familyName,
        userData.givenName
      );
      console.log(`User Created!`);
      console.log(userData);

      await fetchData();
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("create admin error:", e);
      setErrmsg(
        <div>
          管理者の作成に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      resetFormData();
      confirmOk.current?.classList.remove("is-loading");
      modalCreateClose();
    }
  };

  const deleteAdmin = async ():Promise<void> => {
    try {
      const loginUser:any = await Auth.currentUserInfo();
      if(loginUser.username === deleteUserData.id) throw Error("自分自身のアカウントは削除できません。");
      confirmDelete.current?.classList.add("is-loading");

      await deleteAdminUser(deleteUserData.id);
      console.log(`User Deleted!`);
      console.log(deleteUserData);

      await fetchData();
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("delete admin error:", e);
      setErrmsg(
        <div>
          管理者の削除に失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      setDeleteUserData({id:"", familyName:"", givenName:"", email:""});
      confirmDelete.current?.classList.remove("is-loading");
      modalDeleteClose();
    }
  };

  const modalCreateShow = ():void => {
    modalCreate.current?.classList.add("is-active");
  };
  const modalCreateClose = ():void => {
    modalCreate.current?.classList.remove("is-active");
  };

  const modalDeleteShow = ():void => {
    modalDelete.current?.classList.add("is-active");
  };
  const modalDeleteClose = ():void => {
    modalDelete.current?.classList.remove("is-active");
  };

  const createHtml = () => {
    return (
      <>
        <form onSubmit={createAdmin} onReset={modalCreateClose}>
          <header className="modal-card-head">
            <p className="modal-card-title">管理者追加</p>
            <button className="delete" aria-label="close" type="reset"></button>
          </header>
          <section className="modal-card-body">
          <div className="field">
            <label className="label">姓</label>
            <div className="control">
              <input className="input" type="text" name="familyName" onChange={handleFamilyNameData} value={familyNameData} defaultValue="" />
            </div>
          </div>
          <div className="field">
            <label className="label">名</label>
            <div className="control">
              <input className="input" type="text" name="givenName" onChange={handleGivenNameData} value={givenNameData} defaultValue="" />
            </div>
          </div>
          <div className="field">
            <label className="label">メールアドレス</label>
            <div className="control">
              <input className="input" type="text" name="email" onChange={handleEmailDataa} value={emailData} defaultValue="" />
            </div>
          </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" type="submit" ref={confirmOk}>
              作成
            </button>
            <button className="button" type="reset">
              キャンセル
            </button>
          </footer>
        </form>
      </>

    );
  };

  const modalHtml = () => {
    return (
      <div>
        <button className="button is-primary" onClick={modalCreateShow}>
          管理者追加
        </button>
        <div className="modal" ref={modalCreate}>
          <div className="modal-background"></div>
          <div className="modal-card">
            {createHtml()}
          </div>
        </div>

        <div className="modal" ref={modalDelete}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">管理者削除確認</p>
              <button className="delete" aria-label="close" onClick={modalDeleteClose}></button>
            </header>
            <section className="modal-card-body">
              <p className="mb-4">
                <strong>氏名</strong>
                <span className="ml-5">{deleteUserData.familyName + " " + deleteUserData.givenName}</span>
              </p>
              <p className="mb-4">
                <strong>メールアドレス</strong>
                <span className="ml-5">{deleteUserData.email}</span>
              </p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-danger" onClick={deleteAdmin} ref={confirmDelete}>
                削除実行
              </button>
              <button className="button" onClick={modalDeleteClose}>
                キャンセル
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  };

  const usersHtml = () => {
    return (
      <table className="table is-striped">
        <thead>
          <tr>
            <th>姓</th>
            <th>名</th>
            <th>メールアドレス</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          {users.map((v, i) => {
            return (
              <tr key={i}>
                <td>{v.familyName}</td>
                <td>{v.givenName}</td>
                <td>{v.email}</td>
                <td>
                {v.id === currentUser.id ?
                (<button className="button is-danger" disabled>削除</button>)
                :
                (<button
                    className="button is-danger"
                    onClick={() => {
                      setDeleteUserData(v);
                      modalDeleteShow();
                    }}>削除</button>)
                }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {modalHtml()}
      {usersHtml()}
      <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
    </div>
  );
}

export default GPTAdminList;
