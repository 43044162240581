import { useState } from "react";
import { message, Button } from 'antd';
import { ErrorBox } from "../ErrorBox";
import { CheckboxEmptyError } from "../../utils/customErrors"
import { DownloadAdministrator } from "../../interfaces/EventDetailInterfaces";
import { listOrganizations } from "../../api/dynamodbApi";
import CsvDownloader from 'react-csv-downloader';

import { API, Auth, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { ListUsersQuery } from "../../API";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";

const DownloadOrganizationListButton = (organizationIdList:string[])=> {

  const [isLoading, setLoading] = useState(false);
  // エラー表示用
  const [errorActive, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(<div>エラー</div>);
  // メッセージ表示するAPI用のkey
  const key = "OrganizationListDownloader";

  const getData = async ():Promise<Datas> => {
    const managers:DownloadAdministrator[] = [];

    setLoading(true);
    //loading message
    message.open({
      key,
      type: "loading",
      content: "チェックした組織情報をダウンロードしています",
      duration: 0,
    });

    // 渡した組織IDの有無チェック
    if (organizationIdList.length < 1) {
    // メッセージを表示する
    message.open({
        key,
        type: "info",
        content: "組織を選択してください。",
      });
      // 操作を中止するため、実際はこのエラーを表示しない
      throw new CheckboxEmptyError("no checked values.");
    }
    const allOrganizations = await listOrganizations();

    // Amplifyユーザー認証
    const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

    //全manager情報の取得
    let nextToken = null;
    const allUser:any[] = [];
    do{
      const variables = {
        nextToken,
      };
      const resultManagers = (await API.graphql(graphqlOperation(queries.listUsers, variables, authToken)
      )) as GraphQLResult<ListUsersQuery>;
      
      //token更新
      nextToken = resultManagers.data?.listUsers?.nextToken;
      //allManager更新
      allUser.push(...resultManagers.data?.listUsers?.items!)
    }while(nextToken);

    for (const organizationId of organizationIdList){
      const organizationInfo = allOrganizations.find(e => e.id === organizationId);
      const userInfos = allUser.filter(e => e?.organizationID === organizationId);
      for(const userInfo of userInfos)
      {
        const tmp:DownloadAdministrator = {
          organization_id: organizationInfo!.id,
          organization_name: organizationInfo!.name,
          email: userInfo?.email!,
          family_name: userInfo?.familyName!,
          given_name: userInfo?.givenName!,
          family_name_kana: userInfo?.familyNameKana!,
          given_name_kana: userInfo?.givenNameKana!,
        }
        managers.push(tmp);
      }
    }

    // データは空白の場合の処理
    if (managers.length < 1) {
      throw new Error("データを見つかりませんでした。");
    }

    // 成功のメッセージに更新
    message.open({
        key,
        type: "success",
        content: "ダウンロードがまもなく開始されます。",
      }
    );
    
    setLoading(false);
    return managers as Datas;
  };

const handleError = (e: any): void => {
   // ロード中状態を解除
   setLoading(false);
  // 渡した組織IDの有無チェック
  if (e instanceof CheckboxEmptyError) return;
  
  message.destroy(key);

  console.log("get manager data error", e);
  // エラーメッセージ
  setErrorMsg(
    <div>
      登録者データを取得できませんでした。
      <br />
      {e.name}:{e.message}
    </div>
  );
  setError(true);
}

const columns = [
  {
  id: 'organization_id',
  displayName: '組織ID'
  },
  {
    id: 'organization_name',
    displayName: '組織名'
  },
  {
    id: 'email',
    displayName: 'email'
  },
  {
    id: 'family_name',
    displayName: '姓'
  },
  {
    id: 'given_name',
    displayName: '名'
  },
  {
    id: 'family_name_kana',
    displayName: '姓(カナ)'
  },
  {
    id: 'given_name_kana',
    displayName: '名(カナ)'
  }
];

return (
  <>
    <Button
    type="primary"
    className="button is-info ml-6"
    loading={isLoading}
  >
    <CsvDownloader
      filename={'組織情報'}
      suffix={true}
      extension=".csv"
      separator=","
      columns={columns}
      datas={getData}
      disabled={isLoading}
      handleError={handleError}
    >
      ダウンロード組織情報
    </CsvDownloader>
  </Button>
    <ErrorBox msg={errorMsg} active={errorActive} title="内部エラー" setActive={setError} />
  </>
);

}

export default DownloadOrganizationListButton;