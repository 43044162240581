import { Amplify, API, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

/**
 * 管理者かどうかを判定
 */
async function isAdmin(): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/isAdmin";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.get(apiName, path, myInit);
  return result.isAdmin;
}

/**
 * 全体管理者のユーザーリストを取得
 */
async function listAdminUsers(): Promise<
  {
    sub: string;
    email: string;
    family_name: string;
    given_name: string;
  }[]
> {
  const apiName = "AdminQueries";
  const path = "/listAdmin";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.get(apiName, path, myInit);

  return result.Users.map((obj: any) => {
    return {
      sub: obj.Attributes.find((v: any) => v.Name === "sub").Value,
      email: obj.Attributes.find((v: any) => v.Name === "email").Value,
      family_name: obj.Attributes.find((v: any) => v.Name === "family_name").Value,
      given_name: obj.Attributes.find((v: any) => v.Name === "given_name").Value,
    };
  });
}

/**
 * 全体管理者ユーザーを作成
 */
async function createAdminUser(email: string, family_name: string, given_name: string): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/createAdmin";
  const myInit = {
    body: {
      user: {
        email,
        family_name,
        given_name,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.post(apiName, path, myInit);
  return !!result;
}

/**
 * 全体管理者ユーザーを削除
 */
async function deleteAdminUser(sub: string): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/deleteAdmin";
  const myInit = {
    body: {
      user: {
        sub,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.del(apiName, path, myInit);
  return !!result;
}

/**
 * 全体管理者ユーザーを更新
 */
async function updateAdminUser(sub: string, family_name: string, given_name: string): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/updateAdmin";
  const myInit = {
    body: {
      user: {
        sub,
        family_name,
        given_name,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.post(apiName, path, myInit);
  return !!result;
}

/**
 * 組織管理者を登録
 */
async function changeToManager(username: string): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/changeToManager";
  const myInit = {
    body: {
      user: {
        username,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.post(apiName, path, myInit);
  return !!result;
}

/**
 * 一般ユーザーを作成
 * user subを返す(このsubはgraphqlのuserのidとして使われる)
 */
async function createUser(email: string): Promise<string> {
  const apiName = "AdminQueries";
  const path = "/createUser";
  const myInit = {
    body: {
      user: {
        email,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.post(apiName, path, myInit);
  return result.User.Username;
}

/**
 * 退会
 */
async function deleteOrganization(): Promise<boolean> {
  const apiName = "AdminQueries";
  const path = "/deleteOrganization";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.get(apiName, path, myInit);
  return !!result;
}

/**
 * ユーザーを削除
 */
async function deleteUser(username: string) {
  const apiName = "AdminQueries";
  const path = "/deleteUser";
  const myInit = {
    body: {
      user: {
        username,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.del(apiName, path, myInit);
  return !!result;
}

/**
 * ユーザーのroleを変更
 */
async function changeRole(id: string, role: string) {
  const apiName = "AdminQueries";
  const path = "/changeRole";
  const myInit = {
    body: {
      user: {
        id,
        role,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.post(apiName, path, myInit);
  return !!result;
}

export {
  isAdmin,
  listAdminUsers,
  createAdminUser,
  deleteAdminUser,
  updateAdminUser,
  changeToManager,
  createUser,
  deleteOrganization,
  deleteUser,
  changeRole,
};
