import routestable from "../routes/routes";
import React from "react";
import { useState } from "react";
import Footer from "./Footer";
import { useLocation, useRoutes } from "react-router-dom";
import { CheckboxField, PasswordField, TextField, Heading, useTheme, withAuthenticator } from "@aws-amplify/ui-react";
import { RecoilRoot } from "recoil";
import { ConfigProvider } from "antd";
import "@aws-amplify/ui-react/styles.css";
import "antd/dist/reset.css";
import jaJP from "antd/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";

import { ReactComponent as LogoShip } from "../logo.svg";
import { Spinner } from "./Spinner";
import { CommonHeader } from "./CommonHeader";
import Example from "./Example";
import DebugLink from "./DebugLink";

dayjs.locale("ja"); //DatePick年,月,曜日を日本語にする

/**
 * スピナー表示
 *
 * @param {boolean} spinactive - 表示/非表示
 */
export let setSpinner: React.Dispatch<React.SetStateAction<boolean>>;

function App() {
  const [spinactive, setActive] = useState(false);
  setSpinner = setActive;

  const element = useRoutes(routestable);
  const shouldRenderHeader = !(useLocation().pathname.includes("/orgSignUp"));

  /**
   * ここを参考に認証ロジックを入れた
   * https://docs.amplify.aws/ui/auth/authenticator/q/framework/react/
   */

  return (
    <RecoilRoot>
      <ConfigProvider locale={jaJP}>
        <div className="App">
          <Spinner active={spinactive} />
          {shouldRenderHeader ? <CommonHeader/> : null}
          {element}
          <Footer />
          {process.env.NODE_ENV === "development" ? (
            <>
              <Example />
              <DebugLink />
            </>
          ) : null}
        </div>
      </ConfigProvider>
    </RecoilRoot>
  );
}

/**
 * 画像表示はここを参考に
 * https://qiita.com/ssugimoto/items/697ff173cc18ed860ed0
 */
export default withAuthenticator(App, {
  hideSignUp: process.env.REACT_APP_IS_CREATE_ACCOUNT == "false",
  socialProviders: process.env.REACT_APP_IS_GOOGLE_LOGIN == "false" ? [] : ["google"],
  components: {
    Header: LoginHeader,
    Footer: LoginFooter,
    SignUp: {
      FormFields: SignUpFields,
    },
  },
  services: {
    validateCustomSignUp,
  },
});

function LoginHeader() {
  const { tokens } = useTheme();

  return (
    <>
      <Heading level={2} padding={tokens.space.small} textAlign="center" backgroundColor="white">
        <LogoShip />
        法人GPT
      </Heading>
    </>
  );
}

function LoginFooter() {
  return (
    <div>
      <Footer />
    </div>
  );
}

function SignUpFields() {
  const { tokens } = useTheme();
  return (
    <>
      <Heading level={3} padding={tokens.space.small} textAlign="center" backgroundColor="white">
        組織管理者-新規登録
      </Heading>
      <TextField placeholder="yamada-taro@example.com" label="Eメール" name="email" required />
      <PasswordField placeholder="パスワード" label="パスワード" name="password" required />
      <PasswordField placeholder="パスワードの確認" label="パスワードの確認" name="confirm_password" required />
      {/* 利用規約チェックボックス  */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <CheckboxField
          name="terms"
          value="yes"
          label="Terms"
          labelHidden={true}
        />
        <TermsContent />
      </div>
    </>
  );
}

// 利用規約のHTML
function TermsContent() {
  const url = process.env.REACT_APP_TERMS_OF_SERVICE_URL;
  return (
    <div style={{ display: "flex", alignItems: "center", margin: "10px" }}>
      <a href={url} rel="noreferrer" target="_blank">利用規約</a><p>に同意する</p>
    </div>
  )
}

// 登録ボタン状態のカスタム検証
async function validateCustomSignUp(formData: any) {
  if (!formData.terms) {
    return {
      terms: "You must agree to the Terms",
    }
  }
}
