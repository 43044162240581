import { useState } from "react";
import { message, Row, Col, Button, DatePicker } from 'antd';
import { ErrorBox } from "../ErrorBox";
import { CheckboxEmptyError } from "../../utils/customErrors"
import type { RangePickerProps } from 'antd/es/date-picker';
import CsvDownloader from 'react-csv-downloader';
import dayjs from "dayjs";

// API
import * as queries from "../../graphql/queries";
import { TokenLogsByUserIDQuery, GetOrganizationQuery } from '../../API';
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, Auth } from "aws-amplify";

// インターフェース
import { OrgTokenCount, TokenLog, OrgTokenLog } from "../../interfaces/OrganizationTokenInterface";
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";

/**
 * ダウンロードトークンコンポーネント
 */
const TokenCSVDownloadButton = (checkedIDs: string[]): JSX.Element => {
  const { RangePicker } = DatePicker;

  const [dateRange, setDateRange] = useState<[string, string]>([dayjs(Date.now()).format("YYYY/MM"), dayjs(Date.now()).format("YYYY/MM")]);
  const [isLoading, setLoading] = useState(false);

  // エラー表示用
  const [errorActive, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(<div>エラー</div>);
  // メッセージ表示するAPI用のkey
  const key = "TokenCSVDownloader";

  // データを取得して成形
  const getDownloadDatas = async ():Promise<Datas> => {
    // 渡した組織IDの有無チェック
    if (checkedIDs.length < 1) {
      // メッセージを表示する
      message.open({
          key,
          type: "info",
          content: "組織を選択してください。",
        }
      );
      // 操作を中止するため、実際はこのエラーを表示しない
      throw new CheckboxEmptyError("no checked values.");
    }

    // ロード中状態に変更
    setLoading(true);

    // ロード中状態のメッセージを表示
    message.open({
        key,
        type: "loading",
        content: "トークンデータを取得しています。",
        duration: 0,
      }
    );
    const originalDatas = await fetchAndSetData(checkedIDs);

    // メッセージを更新
    message.open({
        key,
        type: "loading",
        content: "データを成形しています。",
        duration: 0,
      }
    );
    const processedDatas = await processData(checkedIDs, originalDatas);
    // データは空白の場合の処理
    if (processedDatas.length < 1) {
      throw new Error("データを見つかりませんでした。");
    }
    
    // 成功のメッセージに更新
    message.open({
        key,
        type: "success",
        content: "ダウンロードがまもなく開始されます。",
      }
    );

    // ロード中状態を解除
    setLoading(false);
    return processedDatas as Datas;
  };

  // DBから生のログデータを取得
  const fetchAndSetData = async (orgIDs: string[]): Promise<OrgTokenLog[]> => {
    // Amplifyユーザー認証
    const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

    // 組織ID別で格納のデータ
    const tokenLogsByOrgID: OrgTokenLog[] = [];
    for (const orgID of orgIDs) {
      let nextToken = null;
      // 組織ユーザーのIDリスト
      const userIDs: string[] = [];
      // DBから組織データを取得する
      do {
        const result = (await API.graphql({
          query: queries.getOrganization,
          authToken: authToken,
          variables: {
            id: orgID,
            nextToken: nextToken,
          },
        }))as GraphQLResult<GetOrganizationQuery>;
        // 次回の検索で使う文字列、nullでしたら中止
        nextToken = result?.data?.getOrganization?.Users?.nextToken;
        // ユーザーIDを取得する
        const users = result?.data?.getOrganization?.Users?.items!;
        const idArray: string[] = users.map((user) => user!.id);
        // 今回の検索結果をユーザーIDの配列に入れる
        userIDs.push(...idArray);
      } while (nextToken);
      // 組織名
      const orgName = ((await API.graphql({
        query: queries.getOrganization,
        authToken: authToken,
        variables: { id: orgID },
      })) as GraphQLResult<GetOrganizationQuery>)?.data?.getOrganization?.name!;

      const orgTokenLogs: TokenLog[] = [];
      // ユーザーごとにループ処理
      for (const userID of userIDs) {
        let nextToken = null;
        const userTokenLogs: TokenLog[] = [];
        // DBからユーザーのトークンログを取得する
        do {
          const result = (await API.graphql({
            query: queries.tokenLogsByUserID,
            authToken: authToken,
            variables: {
              userID: userID,
              nextToken: nextToken,
            },
          }))as GraphQLResult<TokenLogsByUserIDQuery>;
          // 次回の検索で使う文字列、nullでしたら中止
          nextToken = result?.data?.tokenLogsByUserID?.nextToken;

          // データを成形する
          const tokenLogs: TokenLog[] = [];
          // DBから取得したデータから探す
          for (const item of result?.data?.tokenLogsByUserID?.items!) {
            const data: TokenLog = {
              count: item?.count,
              userID: item?.userID,
              createdAt: item?.createdAt,
            };
            tokenLogs.push(data);
          }
          // ユーザーごとの配列
          userTokenLogs.push(...tokenLogs); 
        } while (nextToken);
        // 組織ごとの配列
        orgTokenLogs.push(...userTokenLogs);
      }
      // 組織ID別ですべて格納する
      tokenLogsByOrgID.push({
        orgID: orgID,
        orgName: orgName,
        tokens: orgTokenLogs,
      });
    }

    return tokenLogsByOrgID;
  };

  // 生データを成形・ソート処理。
  const processData = async (orgIDs: string[], tokenLogsByOrgID: OrgTokenLog[]): Promise<OrgTokenCount[]> => {
    // 出力用のCSVデータ変数
    const orgTokenCounts: OrgTokenCount[] = [];

    // 指定された日付の範囲
    const startDate = dayjs(dateRange[0]).startOf("month");
    const endDate = dayjs(dateRange[1]).endOf("month");
    const dateLength = endDate.diff(startDate, "month") + 1;

    // 組織ID別でCSVデータを作る
    for (const id of orgIDs) {
      // 該当組織のデータを取り出す
      const targetOrg = tokenLogsByOrgID.find((item) => item.orgID === id);
      // 該当組織が存在しない場合、次の処理に移行
      if (!targetOrg) continue;
      // 日付の範囲内でループ処理
      for (let i = 0; i < dateLength; i++) {
        // 月
        const targetDate = startDate.add(i, "month").format("YYYYMM");
        // DBから取得したデータから、特定日付の内容を探す
        const existingData = targetOrg.tokens?.filter((item) => dayjs(item.createdAt).format("YYYYMM") === targetDate);
        // 集計トークン量
        let totalCount = 0;
        // 範囲内のデータは存在すると、加算処理
        if (existingData && existingData.length > 0) {
          for (const tokenLog of existingData) {
            totalCount += tokenLog.count ?? 0;
          }
        }
        // 出力用の変数に保存
        orgTokenCounts.push(
          {
            date: targetDate,
            count: totalCount.toString(),
            orgID: targetOrg.orgID,
            orgName: targetOrg.orgName,
          }
        );
      }
    }

    // ソート・日付の降順
    orgTokenCounts.sort((a, b) => b.date!.localeCompare(a.date!));
    return orgTokenCounts;
};

  // 日付け選択ボックスの値を保存
  const handleDateChange = (value: RangePickerProps["value"], dateString: [string, string]): void => {
    console.log('Selected date: ', value);
    console.log('Formatted date: ', dateString);
    setDateRange(dateString);
  };

  // 選択できない日付
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("month");
  }

  // エラー処理
  const handleError = (e: any): void => {
    // 渡した組織IDの有無チェック
    if (e instanceof CheckboxEmptyError) return;
    // ロード中状態を解除
    setLoading(false);
    message.destroy(key);

    console.log("get token data error", e);
    // エラーメッセージ
    setErrorMsg(
      <div>
        トークンデータを取得できませんでした。
        <br />
        {e.name}:{e.message}
      </div>
    );
    setError(true);
  };

  // ダウンロード用のボタン
  const downloadButton = (): JSX.Element => {
    const columns = [
      {
        id: "orgID",
        displayName: "組織ID"
      },
      {
        id: "orgName",
        displayName: "組織名"
      },
      {
        id: "date",
        displayName: "対象年月"
      },
      {
        id: "count",
        displayName: "トークン量"
      },
    ];

    return (
      <>
        <CsvDownloader
          filename={'組織別利用トークン'}
          suffix={true}
          extension=".csv"
          separator=","
          columns={columns}
          datas={getDownloadDatas}
          disabled={isLoading}
          handleError={handleError}>
          <Button
            type="primary"
            className="button is-info"
            loading={isLoading}>
            組織別トークン利用量CSV
          </Button>
        </CsvDownloader>
      </>
    );
  };

  // 日付けボックス
  const dateSelector = (): JSX.Element => {
    return (
      <>
        <span>トークン利用期間：</span>
        <RangePicker
          size="large"
          picker="month"
          allowClear={false}
          disabledDate={disabledDate}
          format="YYYY/MM"
          defaultValue={[dayjs(dateRange[0]), dayjs(dateRange[1])]}
          onChange={handleDateChange}
        />
      </>
    );
  }

  return (
    <>
      <Row>
        <Col>{downloadButton()}</Col>
        <Col>{dateSelector()}</Col>
      </Row>
      <ErrorBox msg={errorMsg} active={errorActive} title="内部エラー" setActive={setError} />
    </>
  );
};

export default TokenCSVDownloadButton;