// チェックボックス・選択していない時用のエラー
class CheckboxEmptyError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CheckboxError";
  }
}

// カタカナ入力は正しくないエラー
class KatakanaError extends Error {
  constructor() {
    super();
    this.name = "KatakanaError";
  }
}

export { CheckboxEmptyError, KatakanaError };