import React from "react";
import { useState } from "react";
import { useCSVReader } from "react-papaparse";
import { createUser } from "../../api/cognitoApi"
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetUserQuery } from "../../API"
import { message, Button} from 'antd';
import { ErrorBox } from "../ErrorBox";
import { isKatakana } from "../../utils/katakana";

interface UploadMember {
	email: string;
	family_name: string;
	given_name: string;
	family_name_kana: string;
	given_name_kana: string;
}

const UploadMemberListButton = (props: any) => {

  const { CSVReader } = useCSVReader();

  const [isLoading, setLoading] = useState(false);
  // エラー表示用
  const [errorActive, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(<div>エラー</div>);
  // メッセージ表示するAPI用のkey
  const key = "MemberListUploader";

  const registerMember = async (uploadMembers:UploadMember[]) => {
    try {
      setLoading(true);
      //loading message
      message.open({
        key,
        type: "loading",
        content: "メンバーを追加しています",
        duration: 0,
      });

      //カナチェック
      const errorMessages = new Set();
      for (const uploadMember of uploadMembers) {
        if (!isKatakana(uploadMember.family_name_kana)) {
          errorMessages.add("セイはカタカナで入力してください。");
        }
        if (!isKatakana(uploadMember.given_name_kana)) {
          errorMessages.add("メイはカタカナで入力してください。");
        }
      }
      if (errorMessages.size > 0) {
        const errorMessage = Array.from(errorMessages).join("\n");
        throw new Error(errorMessage);
      }

      //getManagerOrganizationId
      const info = await Auth.currentUserInfo();
      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();

      const managerId:string = info.username;
      const resultManager = (await API.graphql(
        graphqlOperation(queries.getUser, { id: managerId }, authToken)
      )) as GraphQLResult<GetUserQuery>;
      const organizationID = resultManager.data?.getUser?.organizationID;
      if(organizationID == null){
        throw Error("organaizationIdが取得できませんでした");
      }
      //register
      for(const UploadMember of uploadMembers){
        //add to Cognito
        const id:string = await createUser(UploadMember.email);
        //add to DynamoDB
        const email = UploadMember.email;
        const familyName = UploadMember.family_name;
        const givenName = UploadMember.given_name;
        const familyNameKana = UploadMember.family_name_kana;
        const givenNameKana = UploadMember.given_name_kana;
        const role =  "member";
        const input = {
          input: {
            organizationID,
            id,
            email,
            familyName,
            givenName,
            familyNameKana,
            givenNameKana,
            role
          }
        };
        await API.graphql(graphqlOperation(mutations.createUser, input, authToken));
      }
      props.fetchList();
    } catch(e: any) {
      handleError(e);
    } finally {
      message.destroy(key);
      setLoading(false);
    }
  }

  // エラー処理
  const handleError = (e: any) => {
    // ロード中状態を解除
    setLoading(false);
    message.destroy(key);
    // エラーメッセージ
    let errorMessage = e.message;
    let errorName = e.name;

    if (e.response != null) {
      errorMessage = e.response.data.message;
      errorName = e.name;
    } else if (e.errors != null) {
      errorMessage = e.errors[0].message
      errorName = "Error";
    }
    setErrorMsg(
      <div>
        メンバーの作成に失敗しました
        <br />
        {errorName}:{errorMessage.split('\n').map((line:any, index:any) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
    ))}
      </div>
    );
    setError(true);
  };

    return (
        <>
        <CSVReader
        onUploadAccepted={async (result:any) => {
            const uploadMembers:UploadMember[] = [];
            for(const memberInfo of result.data){
                const uploadMember:UploadMember = {
                    email: memberInfo["email"],
                    family_name: memberInfo["姓"],
                    given_name: memberInfo["名"],
                    family_name_kana: memberInfo["姓(カナ)"],
                    given_name_kana: memberInfo["名(カナ)"],
                }
                uploadMembers.push(uploadMember);
            }
            await registerMember(uploadMembers);
        }}
        config={{
            header:true,
            skipEmptyLines:true
        }}
        accept="text/csv"
        disabled={isLoading}
        >
            {({getRootProps,}: any) => (
            <Button
            type="primary"
            className="button is-info"
            loading={isLoading}
            {...getRootProps()}>
                アップロードメンバー
            </Button>
            )}
        </CSVReader>
        <ErrorBox msg={errorMsg} active={errorActive} title="内部エラー" setActive={setError} />
        </>
    );
}

export default UploadMemberListButton;