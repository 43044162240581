/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTokenLog = /* GraphQL */ `
  query GetTokenLog($id: ID!) {
    getTokenLog(id: $id) {
      id
      direction
      count
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTokenLogs = /* GraphQL */ `
  query ListTokenLogs(
    $filter: ModelTokenLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTokenLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        direction
        count
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const tokenLogsByUserID = /* GraphQL */ `
  query TokenLogsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTokenLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tokenLogsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        direction
        count
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      familyName
      organizationID
      familyNameKana
      givenName
      givenNameKana
      email
      role
      TokenLogs {
        items {
          id
          direction
          count
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        familyName
        organizationID
        familyNameKana
        givenName
        givenNameKana
        email
        role
        TokenLogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const usersByOrganizationID = /* GraphQL */ `
  query UsersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        familyName
        organizationID
        familyNameKana
        givenName
        givenNameKana
        email
        role
        TokenLogs {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      Users {
        items {
          id
          familyName
          organizationID
          familyNameKana
          givenName
          givenNameKana
          email
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        Users {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
