import { API, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";
import Header from "./Header";
import * as queries from "../../graphql/queries";
import { GetUserQuery } from "../../API";

const Manager = () => {
  // ナビゲーション用
  const navigate = useNavigate();
  const signUpPage = '/manager/orgSignUp';

  useEffect(() => {
    checkManagerInfo();
  }, []);

  // 組織管理者情報を確認する
  // 未登録の場合は新規登録画面に移行処理
  const checkManagerInfo = async () => {
    try {
      const userInfo = await Auth.currentUserInfo();
      console.log(userInfo.username);

      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const result = (await API.graphql({
        query: queries.getUser,
        authToken: authToken,
        variables: {
            id: userInfo.username
          },
        },
      )) as GraphQLResult<GetUserQuery>;

      const orgID = result.data?.getUser?.organizationID!;
      console.log(orgID);

      if (orgID === "" || orgID == null) {
        navigate(signUpPage);
      }
    } catch (e: any) {
      console.log("fetch data error:", e);
    }
  };

  return (
    <>
      <Header/>
      <div className="columns">
        <div className="column is-2">
          <SideMenu />
        </div>
        <div className="column">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Manager;