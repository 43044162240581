import { atom } from "recoil";

/**
 * adminAtom value:
 * 0  init
 * 1  user
 * 2  admin
 */
enum UserType {
  Init,
  User,
  Admin,
}

const adminAtom = atom({
  key: "adminAtom",
  default: 0,
});

export { adminAtom, UserType };
