import { useEffect, useState } from "react";
import { Button,Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { getRole, Roles } from "../Top";
import { deleteOrganization } from "../../api/cognitoApi"
import { ErrorBox } from "../ErrorBox";


const MemberShipCancel = () => {

  const [isFoolProofOpen, setFoolProofOpen] = useState(false);

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg] = useState(<div>エラー</div>);

  useEffect(() => {(
    async() => {
    await validationRole();
  })()}, []);

  const navigate = useNavigate();
  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result !== Roles.manager) navigate("/", { replace: true });
  };

  const showFoolProof = () => {
    setFoolProofOpen(true);
  };

  const handleOk = async () => {
    setFoolProofOpen(false);
    //退会処理
    try{
      await deleteOrganization();
      signOut();
    } catch(e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetch list error:", e);
      setErrmsg(
        <div>
          データを取得できませんでした
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    }
  };

  const handleCancel = () => {
    setFoolProofOpen(false);
  };

  const signOut = async (): Promise<void> => {
    try {
      // clear localstorage
      localStorage.clear();

      //ログアウトボタンをクリックしたとき、URLを / に変更する
      navigate("/", { replace: true });
      await Auth.signOut();
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("sign out error:", e);
      setErrmsg(
        <div>
          ログアウトに失敗しました
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    }
  };


  return <>
  <Button type="primary" onClick={showFoolProof} className='button is-danger'>
    退会
  </Button>
  <Modal title="退会確認" open={isFoolProofOpen} onOk={handleOk} onCancel={handleCancel} okButtonProps={{className:'button is-danger'}} cancelButtonProps={{className:'button is-success'}}>
    <p>退会するとメンバー全員がログインできなくなります。</p>
    <p>本当に退会しますか？</p>
  </Modal>
  <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
</>
 
}

export default MemberShipCancel;