import { GraphQLResult } from "@aws-amplify/api-graphql";
import { API, Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetUserQuery } from "../API";
import { isAdmin } from "../api/cognitoApi";
import * as queries from "../graphql/queries";
import { setSpinner } from "./App";
import { ErrorBox } from "./ErrorBox";

export const enum Roles {
  member,
  manager,
  admin,
}

export const getRole = async (userInfo:any):Promise<Roles | null> => {
  if(await isAdmin()) return Roles.admin;
  // Amplifyユーザー認証
  const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  const result = (await API.graphql({
    query: queries.getUser,
    authToken: authToken,
    variables: {
      id: userInfo.username,
    }
  })) as GraphQLResult<GetUserQuery>;
  if(result.data?.getUser?.role ===  "manager") return Roles.manager;
  if(result.data?.getUser?.role ===  "member")  return Roles.member;
  return null;
}

function Top() {

  const navigate = useNavigate();

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg]   = useState(<div>エラー</div>);

  useEffect(() => {
    setSpinner(true);
    routing();
  }, []);

  const routing = async ():Promise<void> => {
    let isLogin = false;
    try { // ログイン状態チェック
      await Auth.currentSession();
      isLogin = true;
    } catch(e) {
    // catchしても処理は続行
    }

    try {
      if(!isLogin) return; //ログイン状態ではないので以下の処理を飛ばす
      const userInfo = await Auth.currentUserInfo();
      const roleString = await getRole(userInfo);
      switch(roleString)
      {
        case Roles.member:
        case Roles.manager:
          navigate("/chat", { replace: true });
          break;
        case Roles.admin:
          navigate("/admin", { replace: true });
          break;
        default:
          navigate("/manager/orgSignUp", { replace: true });
          break;
      }
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetch data error:", e);
      setErrmsg(
        <div>
          権限データを取得できませんでした
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      setSpinner(false);
    }
  }

  return (
    <>
    <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
    </>
  );
}

export default Top;
