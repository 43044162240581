/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTokenLog = /* GraphQL */ `
  mutation CreateTokenLog(
    $input: CreateTokenLogInput!
    $condition: ModelTokenLogConditionInput
  ) {
    createTokenLog(input: $input, condition: $condition) {
      id
      direction
      count
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTokenLog = /* GraphQL */ `
  mutation UpdateTokenLog(
    $input: UpdateTokenLogInput!
    $condition: ModelTokenLogConditionInput
  ) {
    updateTokenLog(input: $input, condition: $condition) {
      id
      direction
      count
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTokenLog = /* GraphQL */ `
  mutation DeleteTokenLog(
    $input: DeleteTokenLogInput!
    $condition: ModelTokenLogConditionInput
  ) {
    deleteTokenLog(input: $input, condition: $condition) {
      id
      direction
      count
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      familyName
      organizationID
      familyNameKana
      givenName
      givenNameKana
      email
      role
      TokenLogs {
        items {
          id
          direction
          count
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      familyName
      organizationID
      familyNameKana
      givenName
      givenNameKana
      email
      role
      TokenLogs {
        items {
          id
          direction
          count
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      familyName
      organizationID
      familyNameKana
      givenName
      givenNameKana
      email
      role
      TokenLogs {
        items {
          id
          direction
          count
          userID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      Users {
        items {
          id
          familyName
          organizationID
          familyNameKana
          givenName
          givenNameKana
          email
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      Users {
        items {
          id
          familyName
          organizationID
          familyNameKana
          givenName
          givenNameKana
          email
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      Users {
        items {
          id
          familyName
          organizationID
          familyNameKana
          givenName
          givenNameKana
          email
          role
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
