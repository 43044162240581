import { useEffect, useState, useRef } from "react";
import { setSpinner } from "../App";
import { ErrorBox } from "../ErrorBox";
import CsvDownloader from 'react-csv-downloader';
import OrganizationMemberEdit from "./OrganizationMemberEdit";
import UploadMemberListButton from "./UploadMemberListButton";
import DownloadMemberListButton from "./DownloadMemberListButton";
import AddOrganizationMemberButton from "./AddOrganizationMemberButton";
import {  Pagination  } from 'antd';

import { API, graphqlOperation, Auth } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as mutation from "../../graphql/mutations";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { GetOrganizationQuery, GetUserQuery } from "../../API";
import { deleteUser } from "../../api/cognitoApi"
import { Datas } from "react-csv-downloader/dist/esm/lib/csv";
import { useNavigate } from "react-router-dom";
import { getRole, Roles } from "../Top";

interface MemberList {
  id: string,
  familyName: string;
  givenName: string;
  familyNameKana: string;
  givenNameKana: string;
  email: string;
  role: string;
}

type props = {
  organizationName:string
  memberIdList:string[]
};

function OrganizationMemberList() {

  const sample: Datas = [{
    email: "",
    family_name: "",
    given_name: "",
    family_name_kana: "",
    given_name_kana: "",
  }]

  const columns = [
    {
      id: 'email',
      displayName: 'email'
    },
    {
      id: 'fimily_name',
      displayName: '姓'
    },
    {
      id: 'given_name',
      displayName: '名'
    },
    {
      id: 'fimily_name_kana',
      displayName: '姓(カナ)'
    },
    {
      id: 'given_name_kana',
      displayName: '名(カナ)'
    }
  ];

  const [users, setUsers] = useState<MemberList[]>([]);
  const [myId, setMyId] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");

  const modalDelete = useRef<HTMLDivElement>(null);
  const confirmDelete = useRef<HTMLButtonElement>(null);

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg] = useState(<div>エラー</div>);

  const [usersChecked, setUsersChecked] = useState<boolean[]>([]);
  const [allCheck, setCheck] = useState(false);

  const [nowPage, setNowPage] = useState(1);
  const [listNum, setListNum] = useState(10);

  useEffect(() => {(
    async() => {
    await validationRole();
    fetchList();
  })()}, []);

  const navigate = useNavigate();
  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result !== Roles.manager) navigate("/", { replace: true });
  };

  const fetchList = async () => {
    try {
      setSpinner(true);
      const userInfo = await Auth.currentUserInfo()
      setMyId(userInfo.username);
      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      const userResult = (await API.graphql({
        query: queries.getUser,
        authToken: authToken,
        variables: {
            id: userInfo.username
          },
        },
      )) as GraphQLResult<GetUserQuery>;

      const orgID = userResult.data?.getUser?.organizationID!;

      const organizationResult = (await API.graphql(
        graphqlOperation(queries.getOrganization, { id: orgID }, authToken)
      )) as GraphQLResult<GetOrganizationQuery>;

      const data = organizationResult.data?.getOrganization?.Users?.items;
      setOrganizationName(organizationResult.data?.getOrganization?.name!);

      const checked: boolean[] = []
      const list = data?.map((v: any): MemberList => {
        checked.push(false);
        return {
          id: v?.id,
          givenName: v?.givenName,
          familyName: v?.familyName,
          givenNameKana: v?.givenNameKana,
          familyNameKana: v?.familyNameKana,
          email: v?.email,
          role: v?.role
        };
      });
      setUsersChecked(checked);
      setCheck(false);

      if(list) setUsers([...list]);
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetch list error:", e);
      setErrmsg(
        <div>
          データを取得できませんでした
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      setSpinner(false);
    }
  };

  const deleteMember = async () => {
    try {
      confirmDelete.current?.classList.add("is-loading");
      const deleteList: string[] = []

      for(let i = 0; i < users.length; i++)
      {
        if(usersChecked[i])
        {
          if(users[i].id === myId) throw new Error("自分を削除しようとしました");
          deleteList.push(users[i].id);
        }
      }

      // Amplifyユーザー認証
      const authToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      for(let i = 0; i < deleteList.length; i++)
      {
        await deleteUser(deleteList[i]);
        await API.graphql(
          graphqlOperation(mutation.deleteUser, { input: { id: deleteList[i] } }, authToken)
        );
      }

      await fetchList();
    } catch (e: any) {
      console.log("deleteUser error", e);
      setErrmsg(
        <div>
          組織メンバーの削除に失敗しました
          <br />
          {e.message}
        </div>
      );
      setError(true);
    } finally {
      confirmDelete.current?.classList.remove("is-loading");
      modalDeleteClose();
    }
  };

  const modalDeleteShow = () => {
    modalDelete.current?.classList.add("is-active");
  };
  const modalDeleteClose = () => {
    modalDelete.current?.classList.remove("is-active");
  };

  const onCheckChange = (id: string) => {
    const checked: boolean[] = []
    users.forEach((user, index) => {
      if (user.id === id) {
        checked.push(!usersChecked[index]);
      } else {
        checked.push(usersChecked[index]);
      }
    });
    setUsersChecked(checked);
  };

  const onPageAllCheckChange = () => {
    const pageListMinNum = nowPage * listNum - listNum;
    const checked = usersChecked;
    const index = pageListMinNum + usersSlice().length;
    for(let i = pageListMinNum; i < index; i++)
    {
      checked[i] = !allCheck;
    }
    setCheck(!allCheck);
    setUsersChecked(checked);
  }

  const onAllCheckChange = () => {
    const checked: boolean[] = []
    const index = users.length;
    for(let i = 0; i < index; i++)
    {
      checked.push(!allCheck);
    }
    setCheck(!allCheck);
    setUsersChecked(checked);
  };

  const pageChange = (page: number) => {
    setNowPage(page);
    setCheck(false);
  }

  const downloadOrgIdUsersId = (): props => {
    const userId: string[] = [];
    users.forEach((user,index) => {
      if(usersChecked[index])
      {
        userId.push(user.id);
      }
    });
    return {
      organizationName:organizationName,
      memberIdList:userId
    };
  }

  const ShowSizeChange = (current: number,size: number) => {
    setNowPage(current);
    setListNum(size);
  }

  // 数件ごとに表示するための分割処理
  const usersSlice = (): MemberList[] => {
    const pageListMaxNum = nowPage * listNum
    return users.slice(pageListMaxNum - listNum, pageListMaxNum);
  }

  const modalHtml = () => {
    return (
      <div>
        <AddOrganizationMemberButton fetchList = {fetchList}/>
        <button className="button is-danger ml-5 mb-5" onClick={modalDeleteShow}>
          メンバー削除
        </button>

        <div className="modal" ref={modalDelete}>
          <div className="modal-background"></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">削除</p>
              <button className="delete" aria-label="close" onClick={modalDeleteClose}></button>
            </header>
            <section className="modal-card-body">
              <p className="mb-4">
                <strong>削除してよろしいですか？</strong>
              </p>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-danger" onClick={deleteMember} ref={confirmDelete}>
                削除
              </button>
              <button className="button" onClick={modalDeleteClose}>
                キャンセル
              </button>
            </footer>
          </div>
        </div>
      </div>
    );
  };

  const usersHtml = () => {
    return (
      <table className="table is-striped">
        <thead>
          <tr>
            <th>すべてチェック</th>
            <th><input
              type="checkbox" checked={allCheck} onChange={onPageAllCheckChange}
            /></th>
            <th>姓</th>
            <th>名</th>
            <th>姓(カナ)</th>
            <th>名(カナ)</th>
            <th>メールアドレス</th>
            <th>権限</th>
          </tr>
        </thead>
        <tbody>
          {usersSlice().map((v, i) => {
            return (
              <tr key={i}>
                <td></td>
                <td><input
                  type="checkbox" checked={usersChecked[nowPage * listNum - listNum + i]} onChange={() => onCheckChange(v.id)}
                /></td>
                <td>{v.familyName}</td>
                <td>{v.givenName}</td>
                <td>{v.familyNameKana}</td>
                <td>{v.givenNameKana}</td>
                <td>{v.email}</td>
                <td>{
                  v.role === "member" ? "メンバー" :
                  v.role === "manager" ? "管理者" : ""
                }</td>
                <td>
                  <OrganizationMemberEdit userData={v} fetchList={fetchList}/>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <UploadMemberListButton fetchList={fetchList}/>
      {DownloadMemberListButton(downloadOrgIdUsersId())}
      <br />
      <CsvDownloader
        filename={'メンバー追加'}
        extension=".csv"
        separator=","
        columns={columns}
        datas={sample}
        text="アップロード用サンプルCSV"
        className="mb-4"
      />
      {modalHtml()}
      <button className="button is-danger mb-1" onClick={onAllCheckChange}>
        表示外も含めてすべてチェックする
      </button>
      {usersHtml()}
      <Pagination
        onChange={(page) => pageChange(page)} onShowSizeChange={(current,size) => ShowSizeChange(current,size)} showSizeChanger={true} current={nowPage} defaultPageSize= {listNum} total={users.length}
      />

      <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
    </div>
    );
}

export default OrganizationMemberList;