import { Pagination, Space } from "antd";
import { useEffect, useState } from "react";
import { listOrganizations } from "../../api/dynamodbApi";
import { setSpinner } from "../App";
import { ErrorBox } from "../ErrorBox";
import DownloadOrganizationListButton from "./DownloadOrganizationListButton";
import TokenCSVDownloadButton from "./OrganizationTokenCSV";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { getRole, Roles } from "../Top";

function OrganizationList() {

  interface Organization {
  id:   string;
  name: string;
  }

  const [orgList, setOrgList]           = useState<Organization[]>([]);
  const [orgListPage, setOrgListPage]   = useState(1);
  const [orgPageSize, setOrgPageSize]   = useState(10);
  const [checkBoxList, setCheckBoxList] = useState<boolean[]>([]);
  const [checkBoxAll, setCheckBoxAll]   = useState<boolean>(false);
  const [checkBoxAllDisplayed, setCheckBoxAllDisplayed]   = useState<boolean[]>([]);
  const [argOrgList, setArgOrgList]     = useState<string[]>([]);

  const [erractive, setError] = useState(false);
  const [errmsg, setErrmsg]   = useState(<div>エラー</div>);

  useEffect(() => {(
    async() => {
      await validationRole();
      await fetchList();
      let arr = [];
      for(let i = 0;i < orgList.length;i++)
      {
        arr.push(false);
      }
      setCheckBoxList(arr);
    })()
  }, []);

  const navigate = useNavigate();
  const validationRole = async () => {
    const userinfo = await Auth.currentUserInfo();
    const result = await getRole(userinfo);
    if(result !== Roles.admin) navigate("/", { replace: true });
  };

  const fetchList = async ():Promise<void> => {
    try {
      setSpinner(true);

      const list = await listOrganizations();
      setOrgList([...list]);
    } catch (e: any) {
      let errorMessage = e.message;
      let errorName = e.name;

      if (e.response != null) {
        errorMessage = e.response.data.message;
        errorName = e.name;
      } else if (e.errors != null) {
        errorMessage = e.errors[0].message
        errorName = "Error";
      }
      console.log("fetch list error:", e);
      setErrmsg(
        <div>
          組織データを取得できませんでした
          <br />
          {errorName}:{errorMessage}
        </div>
      );
      setError(true);
    } finally {
      setSpinner(false);
    }
  };

  const onChangePagenation = (page:number, pageSize:number):void => {
    setOrgListPage(page);
    setOrgPageSize(pageSize);
  }

  const updateArgOrgList = (dataList:boolean[]):void =>
  {
    const argList:string[] = [];
    for(let i = 0; i < dataList.length; i++)
    {
      if(dataList[i]) argList.push(orgList[i].id);
    }

    setArgOrgList(argList);
  }

  const onChangeAllCheckBox = (e:any):void => {
    const checked = checkBoxAll;
    setCheckBoxAll(!checked);
    const values: boolean[] = [...checkBoxList];

    // 「すべてチェック」の状態
    const pageValues: boolean[] = [...checkBoxAllDisplayed];
    // 全ページの「すべてチェック」の状態を変更する
    for(let index = 1; index <= orgPageSize; index++) {
      pageValues[index] = !checked;
    }

    //テーブルに表示されているチェックボックスだけにチェックをつける
    for(let index = 0; index < orgList.length; index++)
    {
      values[index]= !checked;
    }
    setCheckBoxAllDisplayed(pageValues);
    setCheckBoxList(values);
    updateArgOrgList(values);
  };

  const onChangeAllDisplayedCheckBox = (e:any):void => {
    // 「すべてチェック」ページ別の状態を記録する
    const pageValues: boolean[] = [...checkBoxAllDisplayed];
    pageValues[orgListPage] = e.target.checked;
    setCheckBoxAllDisplayed(pageValues);

    const values: boolean[] = [...checkBoxList];
    for(let index = (orgListPage - 1) * orgPageSize; index < Math.min((orgListPage) * orgPageSize, orgList.length); index++)
    {
      values[index]= e.target.checked;
    }
    setCheckBoxList(values);
    updateArgOrgList(values);
  };

  const onChangeCheckBox = (e:any, index:number):void => {
    const values: boolean[] = [...checkBoxList];
    values[index] = e.target.checked;
    setCheckBoxList(values);
    updateArgOrgList(values);
  };

  //listからorgListPage目の10件をListにして返す
  const spliceList = (arr:Organization[]):Organization[] => {
    return arr.slice(
      (orgListPage - 1) * orgPageSize,
      Math.min((orgListPage) * orgPageSize, arr.length)
    );
  }

  const OrganizationHtml = () => {
    const offset: number = (orgListPage - 1) * orgPageSize;
    return (
      <>
        <button className="button is-danger" onClick={onChangeAllCheckBox}>表示外も含めてすべてチェックする</button>
        <table className="table is-striped">
          <thead>
            <tr>
              <th>すべてチェック</th>
              <th><input id="checkBoxAll" type="checkbox" onChange={onChangeAllDisplayedCheckBox} checked={checkBoxAllDisplayed[orgListPage]}/></th>
              <th>組織id</th>
              <th>組織名</th>
            </tr>
          </thead>
          <tbody>
            {spliceList(orgList).map((v, i) => {
              return (
                <tr key={i}>
                  <td></td>
                  <td><input type="checkbox" onChange={(e:any) => onChangeCheckBox(e, i + offset)} checked={checkBoxList[i + offset]} /></td>
                  <td>{v.id}</td>
                  <td>{v.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination onChange={(page, pageSize) =>onChangePagenation(page, pageSize)} current={orgListPage} showSizeChanger total={orgList.length} ></Pagination>
      </>
    );
  };

  return (
  <div>
    <Space>
      {DownloadOrganizationListButton (argOrgList)}
      {TokenCSVDownloadButton(argOrgList)}
    </Space>
    <br/>
    {OrganizationHtml()}
      <ErrorBox msg={errmsg} active={erractive} title="内部エラー" setActive={setError} />
  </div>
  );
}

export default OrganizationList;