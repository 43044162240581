import { Amplify, API, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);

/**
 * 組織一覧を取得(全体管理者のみ)
 */
async function listOrganizations(): Promise<
  {
    id: string;
    name: string;
  }[]
> {
  const apiName = "AdminQueries";
  const path = "/listOrganizations";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.get(apiName, path, myInit);
  return result.map((v: any) => {
    return {
      id: v.id,
      name: v.name,
    };
  });
}

async function listUsers(): Promise<
  {
    id: string;
    email: string;
    familyName: string;
    familyNameKana: string;
    givenName: string;
    givenNameameKana: string;
    role: string;
    organizationID: string;
  }[]
> {
  const apiName = "AdminQueries";
  const path = "/listUsers";
  const myInit = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
    },
  };

  let result = await API.get(apiName, path, myInit);
  return result.map((v: any) => {
    return {
      id: v.id,
      email: v.email,
      familyName: v.familyName,
      familyNameKana: v.familyNameKana,
      givenName: v.givenName,
      givenNameKana: v.givenNameKana,
      role: v.role,
      organizationID: v.organizationID,
    };
  });
}

export { listOrganizations, listUsers };
