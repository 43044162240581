import { Navigate } from "react-router-dom";
//components
import NotFound from "../components/NotFound";
import Top from "../components/Top";

import Chat from "../components/member/Member";
import GPT from "../components/member/GPT";

import Admin from "../components/admin/Admin";
import OrganizationList from "../components/admin/OrganizationList";
import GPTAdminList from "../components/admin/AdminList";

import Manager from "../components/manager/Manager";
import MemberShipCancel from "../components/manager/MemberShipCancel";
import OrganizationMemberList from "../components/manager/OrganizationMemberList";
import OrgSignUp from "../components/manager/OrganizationSignUp";


const element = [
  {
    path: "/",
    element: <Top />,
  },
  {
    path: "/chat",
    element: <Chat />,
    children: [
      {
        path: "",
        element: <GPT />,
      },
    ],
  },
  {
    path: "/manager",
    element: <Manager />,
    children: [
      {
        path: "organizationMemberList",
        element: <OrganizationMemberList />,
      },
      {
        path: "memberShipCancel",
        element: <MemberShipCancel />,
      },
      {
        path: "",
        element: <Navigate to="organizationMemberList" />,
      },
    ],
  },
  {
    path: "/admin",
    element: <Admin />,
    children: [
      {
        path: "organizationList",
        element: <OrganizationList />,
      },
      {
        path: "gptAdminList",
        element: <GPTAdminList />,
      },
      {
        path: "",
        element: <Navigate to="organizationList" />,
      },
    ]
  },
  {
    path: "/manager/orgSignUp",
    element: <OrgSignUp />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default element;
