import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";
import awsconfig from "./aws-exports";

// Bulmaを使えるように設定
// import 'bulma/css/bulma.min.css';
import './css/myBulma.min.css';
import {Amplify } from "aws-amplify";

/*
 * Japanese.txsを追加するとログイン画面が日本語になり、削除すると英語になる。
  */
import "./components/Japanese";

Amplify.configure(awsconfig);

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      {/* @ts-ignore */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>法人GPT</title>
      </Helmet>
      <App />
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
