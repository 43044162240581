import { Link } from "react-router-dom";

function DebugLink() {
  return (
    <>
      <div className="column">
        <p>
          <Link to="/chat">チャット画面サイト</Link>
        </p>
        <p>
          <Link to="/manager">管理者向けサイト</Link>
        </p>
        <p>
          <Link to="/admin">全体管理者向けサイト</Link>
        </p>
      </div>
    </>
  );
}

export default DebugLink;