/** 
 * エラー表示用ダイアログ
 *    エラー表示等のボタンが1つのダイアログ
 *
 *    インターフェーイス
 *        DialogInterface.ts
 *            ErrorBoxProps
 *
 *    使用例
 *        <ErrorBox msg = 'エラー' active = {active} setActive = {setActive}/>
 */

import { useState, useEffect } from "react";
import {ErrorBoxProps} from "../interfaces/DialogInterface";

/* 
 * @module ErrorBox
 * @param {any} msg - 表示メッセージ
 * @param {boolean} active - 表示/非表示
 * @param {Function} setActive - 表示/非表示関数(引数はboolean)
 * @param {string} title - タイトル
 * @param {string} button - 指定すればボタン名を変更
 * @param {string} btnClass? - 指定すればボタンのクラスを変更
 * @return {any} - HTML
 */
export function ErrorBox(props: ErrorBoxProps)
{
  //console.log(props);

  const [active, setActive] = useState(false);
  useEffect(() => {
    setActive(props.active);
  }, [props.active]);

  const button = props.button || "確認";
  const btnClass = props.btnClass || "button";

  function closeModal(id: number)
  {
    //console.log('close click');
    //console.log(active);
 
    setActive(false);
    props.setActive(false);
  }

  function clickButton()
  {
    closeModal(1);
  }

  return (
    <div id="modal-error" className={active ?  "modal is-active" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{props.title}</p>
        </header>
        <section  className="modal-card-body">
          <div className="has-text-centered">
            {props.msg}
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className={btnClass} onClick={clickButton}>{button}</button>
        </footer>
      </div>
    </div>
  );
}
